import {
  Button,
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Switch,
  TextField,
  ThemeProvider,
  Typography,
  Badge,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "../Common/DataTable";
import ConfirmationModal from "../Common/ConfirmationModal";
import "./BuySell.scss";
import Loader from "../Common/Loader";
import {
  ALL,
  BUY_SELL,
  CONDITION_FILTER,
  DEEP_OCEAN_BLUE,
  EXPIRED_FILTER,
  FLEX,
  FLEX_END,
  LISTING_FILTER,
  REINSTATE_MESSAGE,
  SMALL,
  SOLD_FILTER,
  START,
} from "../../utils/constants";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  openConfirmationModal,
  openDeleteReasonModal,
  startLoading,
  stopLoading,
} from "../../redux/Slices/CommonSlice";
import { deleteProduct, fetchAllProduct } from "../../services/buy-sell";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAllResidentNames } from "../../services/resident";
import moment from "moment";
import { fetchAllDeliveryType } from "../../services/delivery-type";
import { fetchAllProductCategory } from "../../services/product-category";
import DeleteReasonModal from "../Common/DeleteReasonModal";
import FlagModal from "../ServiceProvider/FlagModal";
import { fetchBuildingForCommunity } from "../../services/building";
import { theme } from "../Common/CommonStyles";
import { handleInputChange } from "../../helpers/GetMultiselectFilterValues";
import { buySellCsvReport } from "../../services/csvReport";
import styled from "styled-components";

const List = () => {
  const initialFilterValues = localStorage.getItem("buySellFilterValues")
    ? JSON.parse(localStorage.getItem("buySellFilterValues"))
    : {
        category: [ALL],
        resident: [ALL],
        condition: [ALL],
        deliveryType: [ALL],
        freeListing: [ALL],
        sold: [ALL],
        deleteStatus: false,
        expired: [ALL],
        flagged: false,
        buildingName: [ALL],
        apartmentNumber: [ALL],
      };

  const [products, setProducts] = useState([]);
  const [residents, setResidents] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState("asc");
  const [sortColumn, setSortColumn] = useState("Title");
  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const [openFlagPopover, setOpenFlagPopover] = useState(null);
  const [flagMessage, setFlagMessage] = useState({});
  const [buildings, setBuildings] = useState([]);
  const [deleteProductId, setDeleteProductId] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [productCategory, setProductCategory] = useState([]);
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [apartments, setApartments] = useState([]);
  const filterValuesRef = useRef(filterValues);
  const searchValuesRef = useRef(searchValue);
  const rowsPerPageRef = useRef(rowsPerPage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isInitialRender = useRef(true);

  useEffect(() => {
    setPage(0);
    localStorage.setItem(
      "buySellFilterValues",
      JSON.stringify({
        category: filterValues.category,
        resident: filterValues.resident,
        condition: filterValues.condition,
        deliveryType: filterValues.deliveryType,
        freeListing: filterValues.freeListing,
        sold: filterValues.sold,
        deleteStatus: filterValues.deleteStatus,
        expired: filterValues.expired,
        flagged: filterValues.flagged,
        buildingName: filterValues.buildingName,
        apartmentNumber: filterValues.apartmentNumber,
      })
    );
  }, [filterValues]);

  useEffect(() => {
    setPage(0);
    filterValuesRef.current = filterValues;
  }, [filterValues]);

  useEffect(() => {
    searchValuesRef.current = searchValue;
  }, [searchValue]);

  useEffect(() => {
    rowsPerPageRef.current = rowsPerPage;
  }, [rowsPerPage]);

  const getFilterValues = useCallback((filter, constant) => {
    let filterValue = ALL;
    if (filterValuesRef.current[filter].length === 2) {
      filterValue = ALL;
    } else if (filterValuesRef.current[filter].includes(constant[0])) {
      filterValue = constant === EXPIRED_FILTER ? false : true;
    } else if (filterValuesRef.current[filter].includes(constant[1])) {
      filterValue = constant === EXPIRED_FILTER ? true : false;
    } else {
      filterValue = ALL;
    }
    return filterValue;
  }, []);

  const fetchData = useCallback(
    async (pageVal, sortColumn, sortBy) => {
      dispatch(startLoading());
      const response = await fetchAllProduct(
        pageVal || 0,
        rowsPerPage,
        searchValuesRef.current,
        filterValues.category,
        filterValues.resident,
        filterValues.condition,
        filterValues.deliveryType,
        filterValues.freeListing,
        filterValues.sold,
        filterValues.expired,
        filterValues.deleteStatus,
        filterValues.buildingName,
        filterValues.apartmentNumber,
        sortColumn || "Title",
        sortBy || "asc",
        filterValuesRef.current.flagged
      );
      if (response.success && response.data?.products) {
        setTotalRecords(response?.data?.totalRecords || 0);
        setProducts(response?.data?.products);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    },
    [dispatch, rowsPerPage, filterValues]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    const fetchProducts = async () => {
      setPage(0);
      dispatch(startLoading());
      const response = await fetchAllProduct(
        0,
        rowsPerPageRef.current,
        searchValue,
        filterValuesRef.current.category,
        filterValuesRef.current.resident,
        filterValuesRef.current.condition,
        filterValuesRef.current.deliveryType,
        filterValuesRef.current.freeListing,
        filterValuesRef.current.sold,
        filterValuesRef.current.expired,
        filterValuesRef.current.deleteStatus,
        filterValuesRef.current.buildingName,
        filterValuesRef.current.apartmentNumber,
        sortColumn || "Title",
        sortBy || "asc",
        filterValuesRef.current.flagged
      );
      if (response.success && response.data?.products) {
        setTotalRecords(response?.data?.totalRecords || 0);
        setProducts(response?.data?.products);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    };
    const debouncer = setTimeout(() => {
      fetchProducts();
    }, 500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [searchValue, dispatch, sortBy, sortColumn, getFilterValues]);

  const fetchProductCategory = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllProductCategory();
    if (response.success && response.data) {
      setProductCategory(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  useEffect(() => {
    fetchProductCategory();
  }, [fetchProductCategory]);

  useEffect(() => {
    const fetchBuildings = async () => {
      const buildingsResponse = await fetchBuildingForCommunity(1);
      if (buildingsResponse.success && buildingsResponse.data) {
        setBuildings(buildingsResponse.data);
      } else {
        enqueueSnackbar(buildingsResponse.data, {
          variant: "error",
        });
      }
    };
    dispatch(startLoading());
    fetchBuildings();
    dispatch(stopLoading());
  }, [dispatch]);

  useEffect(() => {
    const filteredApartments = () => {
      let allApartments = [];
      buildings.map((building) =>
        building?.apartments.map((apartment) =>
          allApartments.push({ ...apartment, building: building })
        )
      );
      setApartments(allApartments);
    };
    filteredApartments();
  }, [buildings]);

  const getApartmentsByBuilding = (buildingId) => {
    buildingId.length === 0 && buildingId.push(ALL);
    const selectedBuilding = buildingId.filter((val) => val !== ALL);
    const lastIndex = buildingId.length - 1;
    const lastElement = buildingId[lastIndex];

    const apartmentsBySelectedBuilding = [];
    if (lastElement === ALL) {
      buildings.forEach((apartment) => {
        apartment.apartments.forEach((ap) =>
          apartmentsBySelectedBuilding.push({ ...ap, building: apartment })
        );
      });
      setApartments(apartmentsBySelectedBuilding);
    } else {
      let apartmentByBuilding = buildings.filter((building) =>
        selectedBuilding.includes(building.id)
      );
      apartmentByBuilding.forEach((apartment) => {
        apartment.apartments.forEach((ap) =>
          apartmentsBySelectedBuilding.push({ ...ap, building: apartment })
        );
      });
      setApartments(apartmentsBySelectedBuilding);
    }
    setFilterValues((prev) => ({
      ...prev,
      apartmentNumber: [ALL],
      resident: [ALL],
    }));
  };

  const fetchDeliveryType = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllDeliveryType();
    if (response.success && response.data) {
      setDeliveryTypes(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  useEffect(() => {
    fetchDeliveryType();
  }, [fetchDeliveryType]);

  const fetchResidentsAll = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllResidentNames();
    if (response.success && response.data) {
      setResidents(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  useEffect(() => {
    fetchResidentsAll();
  }, [fetchResidentsAll]);

  const getResidentsByApartments = () => {
    setFilterValues((prev) => ({
      ...prev,
      resident: [ALL],
    }));
  };

  const handleDeletedChange = (event) => {
    setFilterValues((prev) => ({
      ...prev,
      deleteStatus: event.target.checked,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData(newPage, sortColumn, sortBy);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    rowsPerPageRef.current = parseInt(event.target.value, 10);
    setPage(0);
  };

  const sortOrderHandler = (header) => {
    setSortBy(sortBy === "asc" ? "desc" : "asc");
    setSortColumn(header);
  };

  const onView = (product) => {
    navigate(`/admin/buy-sell/view/${product?.buySellItem?.id}`);
  };

  const onEdit = (product) => {
    navigate(`/admin/buy-sell/edit/${product?.buySellItem?.id}`);
  };

  const onDelete = (product) => {
    setDeleteProductId(product?.buySellItem?.id);
    if (product?.buySellItem?.deleted) {
      setConfirmationMessage(REINSTATE_MESSAGE);
      dispatch(openConfirmationModal());
    } else {
      dispatch(openDeleteReasonModal());
    }
  };

  const handleClick = (data, event) => {
    window.open(`/admin/buy-sell/flags/${data?.buySellItem?.id}`, "_blank");
  };

  const deleteHandler = async (values) => {
    dispatch(startLoading());
    const response = await deleteProduct(deleteProductId, values?.reason);
    fetchData(page, sortColumn, sortBy);
    if (response.success && response.data) {
      dispatch(stopLoading());
      enqueueSnackbar(response.data.message, { variant: "success" });
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  };

  const flaggedHandler = (e) => {
    setFilterValues((prev) => ({ ...prev, flagged: e.target.checked }));
  };

  const CustomBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "slategrey", // Custom color
      color: "white",
    },
  }));

  const clearHandler = () => {
    setFilterValues(() => ({
      category: [ALL],
      resident: [ALL],
      condition: [ALL],
      deliveryType: [ALL],
      freeListing: [ALL],
      sold: [ALL],
      deleteStatus: false,
      expired: [ALL],
      flagged: false,
      buildingName: [ALL],
      apartmentNumber: [ALL],
    }));
    setSearchValue("");
  };

  const fetchTitle = (row) => {
    return (
      <Grid container>
        {row.buySellItem.deleted ? (
          <p>
            &nbsp;
            <CustomBadge
              overlap="circular"
              variant="dot"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            ></CustomBadge>
            &nbsp;&nbsp;
            <span>{`${row?.buySellItem?.title.trim()} `}</span>
          </p>
        ) : (
          <>
            {" "}
            <span>{` ${row?.buySellItem?.title}`} </span>
          </>
        )}
      </Grid>
    );
  };

  const fetcResidentName = (row) => {
    return (
      <Grid container>
        {row.buySellItem.resident.deleted ? (
          <p>
            &nbsp;
            <Badge variant="dot" color="error" anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              customStyle={{ background: "slategrey", color: "slategrey" }}
              style={{ background: "slategrey", color: "slategrey" }}
            ></Badge>
            &nbsp;
            <span>{`${row?.buySellItem?.resident?.firstName} ${row?.buySellItem?.resident?.lastName} | ${row?.buySellItem?.resident?.apartment?.number} | ${row?.buySellItem?.resident?.apartment?.building?.name}`}</span>
          </p>
        ) : (
          <p>
            {" "}
            &nbsp;
            {` ${row?.buySellItem?.resident?.firstName} ${row?.buySellItem?.resident?.lastName} | ${row?.buySellItem?.resident?.apartment?.number} | ${row?.buySellItem?.resident?.apartment?.building?.name}   `}
          </p>
        )}
      </Grid>
    );
  };

  const downloadHandler = async () => {
    const response = await buySellCsvReport(
      searchValue,
      filterValuesRef.current.category,
      filterValuesRef.current.resident,
      filterValuesRef.current.condition,
      filterValuesRef.current.deliveryType,
      filterValuesRef.current.freeListing,
      filterValuesRef.current.sold,
      filterValuesRef.current.expired,
      filterValuesRef.current.buildingName,
      filterValuesRef.current.apartmentNumber,
      filterValuesRef.current.deleteStatus,
      sortColumn || "Title",
      sortBy || "asc",
      filterValuesRef.current.flagged
    );
    if (response.data) {
      const fileName = "Buy-sell-report.csv";
      const file = new Blob([response.data], { type: "application/csv" });
      const url = window.URL.createObjectURL(file);
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = url;
      anchor.click();
    } else {
      enqueueSnackbar(response.data, { variant: "error" });
    }
  };

  return (
    <Paper className="container" elevation={4}>
      <Grid container spacing={2} paddingBottom={2}>
        <Grid item md={6}>
          <OutlinedInput
            fullWidth
            placeholder="Search by title, description"
            size={SMALL}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            startAdornment={
              <InputAdornment position={START}>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item md={6}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            options={[
              ALL,
              ...filterValues?.category.filter(
                (selectedValue) => selectedValue !== ALL
              ),
              ...productCategory
                .map((category) => category.id)
                .filter(
                  (option) =>
                    !filterValues?.category.includes(option) && option !== ALL
                ),
            ]}
            getOptionLabel={(option) => {
              const category = [
                { id: ALL, name: ALL },
                ...productCategory,
              ].find((s) => s.id === option);
              return category?.name || "";
            }}
            value={filterValues?.category}
            onChange={(_, newValue) => {
              let event = {
                target: {
                  value: newValue,
                },
              };
              handleInputChange(
                event,
                "category",
                setFilterValues,
                filterValues,
                () => {},
                ALL
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Category" name="category" />
            )}
            renderTags={(selected, getTagProps) => {
              const displayedTags = selected.slice(0, 2);
              const hiddenTagsCount = selected.length - displayedTags.length;
              return (
                <Box display="flex" alignItems="center">
                  {displayedTags.map((value, index) => (
                    <Chip
                      size="small"
                      key={value}
                      label={
                        value === ALL
                          ? ALL
                          : productCategory.find(
                              (category) => category.id === value
                            )?.name
                      }
                      {...getTagProps({ index })}
                    />
                  ))}
                  {hiddenTagsCount > 0 && <Box ml={1}>+{hiddenTagsCount}</Box>}
                </Box>
              );
            }}
          />
        </Grid>
        <Grid item md={4}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            options={[
              ALL,
              ...filterValues?.buildingName.filter(
                (selectedValue) => selectedValue !== ALL
              ),
              ...buildings
                .map((building) => building.id)
                .filter(
                  (option) =>
                    !filterValues?.buildingName.includes(option) &&
                    option !== ALL
                ),
            ]}
            getOptionLabel={(option) => {
              const building = [{ id: ALL, name: ALL }, ...buildings].find(
                (s) => s.id === option
              );
              return building?.name || "";
            }}
            value={filterValues?.buildingName}
            onChange={(_, newValue) => {
              let event = {
                target: {
                  value: newValue,
                },
              };

              handleInputChange(
                event,
                "buildingName",
                setFilterValues,
                filterValues,
                () => {},
                ALL
              );

              getApartmentsByBuilding(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Building Name" name="building" />
            )}
            renderTags={(selected, getTagProps) => {
              const displayedTags = selected.slice(0, 1);
              const hiddenTagsCount = selected.length - displayedTags.length;
              return (
                <Box display="flex" alignItems="center">
                  {displayedTags.map((value, index) => (
                    <Chip
                      size="small"
                      key={value}
                      label={
                        value === ALL
                          ? ALL
                          : buildings.find((building) => building.id === value)
                              ?.name
                      }
                      {...getTagProps({ index })}
                    />
                  ))}
                  {hiddenTagsCount > 0 && <Box ml={1}>+{hiddenTagsCount}</Box>}
                </Box>
              );
            }}
          />
        </Grid>
        <Grid item md={4}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            options={[
              ALL,
              ...apartments
                .filter((option) => {
                  return (
                    filterValues.buildingName.includes(ALL) ||
                    filterValues.buildingName.includes(option.building?.id)
                  );
                })
                .map((apartment) => apartment.id)
                .filter(
                  (option) =>
                    !filterValues?.apartmentNumber.includes(option) &&
                    option !== ALL
                ),
            ]}
            getOptionLabel={(option) => {
              const apartment = [{ id: ALL, name: ALL }, ...apartments].find(
                (s) => s.id === option
              );
              let apartmentName = "";
              if (apartment && apartment.name) {
                apartmentName = `${apartment?.name} ${
                  apartment.building ? " - " + apartment.building.name : ""
                }`;
              } else {
                apartmentName = `${apartment?.apartmentNumber} ${
                  apartment.building ? " - " + apartment.building.name : ""
                }`;
              }
              return apartmentName;
            }}
            value={filterValues?.apartmentNumber}
            onChange={(_, newValue) => {
              let event = {
                target: {
                  value: newValue,
                },
              };
              handleInputChange(
                event,
                "apartmentNumber",
                setFilterValues,
                filterValues,
                () => {},
                ALL
              );
              getResidentsByApartments();
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Apartment Number"
                name="apartment"
              />
            )}
            renderTags={(selected, getTagProps) => {
              const displayedTags = selected.slice(0, 1);
              const hiddenTagsCount = selected.length - displayedTags.length;
              return (
                <Box display="flex" alignItems="center">
                  {displayedTags.map((value, index) => (
                    <Chip
                      size="small"
                      key={`${value}-${index}`}
                      label={
                        value === ALL
                          ? ALL
                          : apartments.find(
                              (apartment) => apartment.id === value
                            )?.apartmentNumber
                      }
                      {...getTagProps({ index })}
                    />
                  ))}
                  {hiddenTagsCount > 0 && <Box ml={1}>+{hiddenTagsCount}</Box>}
                </Box>
              );
            }}
          />
        </Grid>
        <Grid item md={4}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            options={[
              ALL,
              ...filterValues?.resident.filter(
                (selectedValue) => selectedValue !== ALL
              ),
              ...residents
                .filter(
                  (option) =>
                    (filterValues.apartmentNumber.includes(ALL) ||
                      filterValues.apartmentNumber.includes(
                        option?.apartment?.id
                      )) &&
                    (filterValues.buildingName.includes(ALL) ||
                      filterValues.buildingName.includes(
                        option?.apartment?.building?.id
                      ))
                )
                .map((resident) => resident.id)
                .filter(
                  (option) =>
                    !filterValues?.resident.includes(option) && option !== ALL
                ),
            ]}
            getOptionLabel={(option) => {
              const resident = [{ id: ALL, name: ALL }, ...residents].find(
                (s) => s.id === option
              );
              let residentName = resident?.name;
              if (resident && resident.name) {
                residentName = `${resident?.name}  ${
                  resident.apartment?.building
                    ? " - " +
                      resident.apartment?.building.name +
                      " - " +
                      resident?.apartment?.number
                    : ""
                }`;
              }
              return residentName;
            }}
            value={filterValues?.resident}
            onChange={(_, newValue) => {
              let event = {
                target: {
                  value: newValue,
                },
              };
              handleInputChange(
                event,
                "resident",
                setFilterValues,
                filterValues,
                () => {},
                ALL
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Resident Name"
                name="residentName"
              />
            )}
            renderTags={(selected, getTagProps) => {
              const displayedTags = selected.slice(0, 1);
              const hiddenTagsCount = selected.length - displayedTags.length;
              return (
                <Box display="flex" alignItems="center">
                  {displayedTags.map((value, index) => (
                    <Chip
                      size="small"
                      key={value}
                      label={
                        value === ALL
                          ? ALL
                          : residents.find((resident) => resident.id === value)
                              ?.name
                      }
                      {...getTagProps({ index })}
                    />
                  ))}
                  {hiddenTagsCount > 0 && <Box ml={1}>+{hiddenTagsCount}</Box>}
                </Box>
              );
            }}
          />
        </Grid>
        <Grid item md={4}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            options={[
              ALL,
              ...filterValues?.freeListing.filter(
                (selectedValue) => selectedValue !== ALL
              ),
              ...LISTING_FILTER.filter(
                (option) =>
                  !filterValues?.freeListing.includes(option) && option !== ALL
              ),
            ]}
            value={filterValues?.freeListing}
            onChange={(_, newValue) => {
              let event = {
                target: {
                  value: newValue,
                },
              };
              handleInputChange(
                event,
                "freeListing",
                setFilterValues,
                filterValues,
                () => {},
                ALL
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Price Status" name="listing" />
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            options={[
              ALL,
              ...filterValues?.sold.filter(
                (selectedValue) => selectedValue !== ALL
              ),
              ...SOLD_FILTER.filter(
                (option) =>
                  !filterValues?.sold.includes(option) && option !== ALL
              ),
            ]}
            value={filterValues?.sold}
            onChange={(_, newValue) => {
              let event = {
                target: {
                  value: newValue,
                },
              };
              handleInputChange(
                event,
                "sold",
                setFilterValues,
                filterValues,
                () => {},
                ALL
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Sold Status" name="sold" />
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            options={[
              ALL,
              ...filterValues?.expired.filter(
                (selectedValue) => selectedValue !== ALL
              ),
              ...EXPIRED_FILTER.filter(
                (option) =>
                  !filterValues?.expired.includes(option) && option !== ALL
              ),
            ]}
            value={filterValues?.expired}
            onChange={(_, newValue) => {
              let event = {
                target: {
                  value: newValue,
                },
              };
              handleInputChange(
                event,
                "expired",
                setFilterValues,
                filterValues,
                () => {},
                ALL
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Expired Status" name="expired" />
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            options={[
              ALL,
              ...filterValues?.condition.filter(
                (selectedValue) => selectedValue !== ALL
              ),
              ...CONDITION_FILTER.filter(
                (option) =>
                  !filterValues?.condition.includes(option) && option !== ALL
              ),
            ]}
            value={filterValues?.condition}
            onChange={(_, newValue) => {
              let event = {
                target: {
                  value: newValue,
                },
              };
              handleInputChange(
                event,
                "condition",
                setFilterValues,
                filterValues,
                () => {},
                ALL
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Condition" name="condition" />
            )}
            renderTags={(selected, getTagProps) => {
              const displayedTags = selected.slice(0, 2);
              const hiddenTagsCount = selected.length - displayedTags.length;
              return (
                <Box display="flex" alignItems="center">
                  {displayedTags.map((value, index) => (
                    <Chip
                      size="small"
                      key={value}
                      label={
                        value === ALL
                          ? ALL
                          : CONDITION_FILTER.find(
                              (condition) => condition === value
                            )
                      }
                      {...getTagProps({ index })}
                    />
                  ))}
                  {hiddenTagsCount > 0 && <Box ml={1}>+{hiddenTagsCount}</Box>}
                </Box>
              );
            }}
          />
        </Grid>
        <Grid item md={4}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            options={[
              ALL,
              ...filterValues?.deliveryType.filter(
                (selectedValue) => selectedValue !== ALL
              ),
              ...deliveryTypes
                .map((deliveryType) => deliveryType.id)
                .filter(
                  (option) =>
                    !filterValues?.deliveryType.includes(option) &&
                    option !== ALL
                ),
            ]}
            getOptionLabel={(option) => {
              const deliveryType = [
                { id: ALL, name: ALL },
                ...deliveryTypes,
              ].find((s) => s.id === option);
              return deliveryType?.name || "";
            }}
            value={filterValues?.deliveryType}
            onChange={(_, newValue) => {
              let event = {
                target: {
                  value: newValue,
                },
              };
              handleInputChange(
                event,
                "deliveryType",
                setFilterValues,
                filterValues,
                () => {},
                ALL
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Delivery Type"
                name="deliveryType"
              />
            )}
          />
        </Grid>
        <Grid item>
          <FormControl>
            <FormControlLabel
              key={"deleted"}
              control={
                <Checkbox
                  sx={{
                    color: DEEP_OCEAN_BLUE,
                  }}
                  size={SMALL}
                  checked={filterValues.flagged}
                  onChange={flaggedHandler}
                />
              }
              label={
                <Typography className="label__fontsize">
                  Only Flagged
                </Typography>
              }
            />
          </FormControl>
        </Grid>
        <Grid item>
          <ThemeProvider theme={theme}>
            <FormControl
              size={SMALL}
              fullWidth
              className="ServiceProviderCreate__switch__container"
            >
              <Typography className="Deleted__Toggle">Active</Typography>
              <Switch
                name="deleted"
                checked={filterValues?.deleteStatus}
                onChange={handleDeletedChange}
              />
              <Typography className="Deleted__Toggle">Deleted</Typography>
            </FormControl>
          </ThemeProvider>
        </Grid>
      </Grid>
      <Grid display={FLEX} container spacing={2} justifyContent={FLEX_END}>
        <Grid item className="resident__clear__button">
          <Button
            variant="contained"
            className="submit__button"
            onClick={clearHandler}
          >
            Clear All
          </Button>
        </Grid>
        <Grid item className="resident__clear__button">
          <Button
            variant="contained"
            className="submit__button"
            onClick={downloadHandler}
          >
            Download CSV
          </Button>
        </Grid>
      </Grid>
      <Grid container sx={{ padding: 1 }} marginBottom={1}>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "left",
          }}
        >
          <Typography
            variant="body1"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Badge badgeContent={"S"}></Badge>{" "}
            <span style={{ marginLeft: "25px", fontWeight: 500 }}>
              {" "}
              - Sold Out
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "left",
          }}
        >
          <Typography
            variant="body1"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Badge variant="dot" color="error"></Badge>
            <span style={{ marginLeft: "20px", fontWeight: 500 }}>
              {" "}
              - Deleted User
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "left",
          }}
        >
          <Typography
            variant="body1"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <CustomBadge
              // color="grey"
              overlap="circular"
              variant="dot"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              customStyle={{ background: "slategrey", color: "slategrey" }}
              style={{ background: "slategrey", color: "slategrey" }}
            ></CustomBadge>
            <span style={{ marginLeft: "10px", fontWeight: 500 }}>
              - Deleted Product
            </span>
          </Typography>
        </Grid>
      </Grid>
      <DataTable
        from={BUY_SELL}
        headers={[
          { label: "Title" },
          { label: "Resident" },
          { label: "Description" },
          filterValues?.flagged ? { label: "Flag Count" } : null,
          { label: "Category" },
          { label: "Condition" },
          { label: "Delivery Type" },
          { label: "Free" },
          { label: "Created On" },
          { label: "Actions" },
        ]}
        data={products.map((row) => {
          return {
            id: row?.id,
            commonColumns: [
              fetchTitle(row),
              fetcResidentName(row),
              row?.buySellItem?.description,
              filterValues?.flagged ? row?.buySellItemFlagsCount : null,
              row?.productCategory?.name,
              row?.buySellItem?.condition,
              row?.buySellItem?.deliveryType?.name,
              row?.buySellItem?.freeListing ? "Yes" : "No",
              moment(row?.buySellItem?.createdDate).format("DD MMM YYYY"),
            ],
            data: row,
          };
        })}
        onView={onView}
        onEdit={onEdit}
        onDelete={onDelete}
        sortOrderHandler={sortOrderHandler}
        totalRecords={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleClick={handleClick}
        sortBy={sortBy}
        sortColumn={sortColumn}
      />
      <ConfirmationModal
        message={confirmationMessage}
        onOkHandler={deleteHandler}
        onCancelHandler={() => {}}
        cancelText="No"
        okText="Yes"
      />
      <DeleteReasonModal
        onOkHandler={deleteHandler}
        onCancelHandler={() => {}}
        cancelText="Cancel"
        okText="Submit"
        from={BUY_SELL}
      />
      <FlagModal
        openFlagPopover={openFlagPopover}
        setOpenFlagPopover={setOpenFlagPopover}
        flagMessage={flagMessage}
        setFlagMessage={setFlagMessage}
      />
      <Loader />
    </Paper>
  );
};

export default List;
