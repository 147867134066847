import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  Tooltip,
  Snackbar,
  Alert,
  IconButton
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Loader from "../Common/Loader";
import { Link, useNavigate, useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import {
  AUTO,
  BLACK,
  CENTER,
  DEEP_OCEAN_BLUE,
  FLEX,
  NAME_PREFIX_TYPES,
  SMALL,
  TEXT_COLOR,
  RED,
  SERVICE_PROVIDER_EDIT,
  SERVICE_LOCATION,
  ROW,
  HOMEBASED_MESSAGE,
} from "../../utils/constants";
import { Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { fetchAllServiceCategory } from "../../services/service-category";
import debounce from "lodash/debounce";
import {
  openConfirmationModal,
  openOperationalHoursModal,
  closeOperationalHoursModal,
  startLoading,
  stopLoading,
} from "../../redux/Slices/CommonSlice";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import ImagePicker from "./ImagePicker";
import { fetchAllPreferredLanguage } from "../../services/preferred-language";
import { fetchAllResidentNames } from "../../services/resident";
import "./ServiceProvider.scss";
import {
  deleteServiceProviderImage,
  fetchByBusinessName,
  checkServiceExistsByPhoneNumber,
  fetchServiceProvider,
  fetchServiceProviderTncAgreement,
  fetchHomeBasedApprovalDocument,
  updateServiceProvider,
  uploadImage,
  fetchCountryCodes,
  updateServiceProviderTncProviderDate,
  sendServiceProviderTerms,
  approveServiceProvider,
  uploadTncPdf,
  uploadApprovalPdf,
} from "../../services/service-provider";
import PhoneAutocomplete from "./PhoneAutocomplete";
import BusinessNameAutocomplete from "./BusinessNameAutocomplete";
import avatar from "../../assets/images/avatar.png";
import Validator from "./Validator";
import UploadPdf from "./UploadPdf";
import { OpenPdfViewer } from "../../helpers/OpenPdfViewer";
import ConfirmationModal from "../Common/ConfirmationModal";
import ScheduleFormModal from "./ScheduleFormModal";

const Edit = (props) => {
  const breadcrumbs = [
    <Link
      key="1"
      color={DEEP_OCEAN_BLUE}
      to={"/admin/service-provider/all"}
      className="breadcrumb__link"
    >
      Service Provider
    </Link>,
    <Typography key="3" color={BLACK}>
      Edit
    </Typography>,
  ];

  const dispatch = useDispatch();
  const [serviceCategories, setServiceCategories] = useState([]);
  const [preferredLanguage, setPreferredLanguage] = useState([]);
  const [residents, setResidents] = useState([]);
  const [serviceProvider, setServiceProvider] = useState(null);
  const [tncPdfUrl, setTncPdfUrl] = useState(null);
  const [homeBasedPdfUrl, setHomeBasedPdfUrl] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [phoneData, setPhoneData] = useState([]);
  const [selectedPhoneData, setSelectedPhoneData] = useState(null);
  const [businessNameData, setBusinessNameData] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [selectedBusinessNameData, setSelectedBusinessNameData] = useState(null);
  const [countryCodes, setCountryCodes] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState(``);
  const approvedById = localStorage.getItem("id");
  const [resides, setResides] = useState(false);
  const [spBusinessHours, setSpBusinessHours] = useState(null);
  const [priceErrors, setPriceErrors] = useState({});
  const [residentProviderValue, setResidentProviderValue] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [tncModal, setTncModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);

  const fetchServiceCategories = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllServiceCategory();
    if (response.success && response.data) {
      setServiceCategories(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  const fetchAllCountryCodes = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchCountryCodes();
    if (response.success && response.data) {
      const sortedValues = response.data.sort((a, b) =>
        a.code.localeCompare(b.code)
      );

      setCountryCodes(sortedValues);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  const fetchPreferredLanguage = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllPreferredLanguage();
    if (response.success && response.data) {
      setPreferredLanguage(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  const fetchResidentNames = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllResidentNames();
    if (response.success && response.data) {
      setResidents(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  const fetchServiceProviderById = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchServiceProvider(id);
    if (response.success && response.data) {
      setServiceProvider(response?.data);
      setResides(response?.data?.serviceProvider?.residentProvider !== null);
      setResidentProviderValue(
        response?.data?.serviceProvider?.residentProvider !== null
          ? response?.data?.serviceProvider?.residentProvider?.id
          : 0
      );
      setSelectedCountryCode(response?.data?.serviceProvider?.countryCode);
      const operationalHours = response?.data?.businessHours;
      if (operationalHours) {
        setSpBusinessHours(operationalHours);
      }
      if (response.data.serviceProvider.tncKey !== null) {
        const tncPdfLinkresponse = await fetchServiceProviderTncAgreement(
          response.data.serviceProvider.id
        );
        if (tncPdfLinkresponse.success && tncPdfLinkresponse.data) {
          const tncPdfUrl = tncPdfLinkresponse.data.fileUrl;

          // Convert the URL to a File object
          const filename = `${tncPdfLinkresponse.data.fileName}.pdf`;
          const file = await urlToFile(tncPdfUrl, filename);
          setTncPdfUrl(file);
        }
      }
      if (response.data.serviceProvider.homeBasedKey !== null) {
        const homeBasedApprovalLink = await fetchHomeBasedApprovalDocument(
          response.data.serviceProvider.id
        );
        if (homeBasedApprovalLink.success && homeBasedApprovalLink.data) {
          const approvalPdfUrl = homeBasedApprovalLink.data.fileUrl;

          // Convert the URL to a File object
          const filename = `${homeBasedApprovalLink.data.fileName}.pdf`;
          const file = await urlToFile(approvalPdfUrl, filename);
          setHomeBasedPdfUrl(file);
        }
      }
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch, id]);

  async function urlToFile(url, filename) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: "application/pdf" });
  }

  useEffect(() => {
    fetchServiceCategories();
  }, [fetchServiceCategories]);

  useEffect(() => {
    fetchPreferredLanguage();
  }, [fetchPreferredLanguage]);

  useEffect(() => {
    fetchResidentNames();
  }, [fetchResidentNames]);

  useEffect(() => {
    fetchAllCountryCodes();
  }, [fetchAllCountryCodes]);

  useEffect(() => {
    fetchServiceProviderById();
  }, [fetchServiceProviderById]);

  const onResend = async (values) => {
    setTncModal(false);
    if (values?.termsPdf) {
      dispatch(startLoading());
      await uploadTncPdf(values.termsPdf, id, approvedById, true);
    }
    const result = await sendServiceProviderTerms(
      serviceProvider?.serviceProvider?.id
    );

    if (result.success) {
      dispatch(stopLoading());
      enqueueSnackbar(
        "Service Provider Terms and Conditions sent successfully",
        {
          variant: "success",
        }
      );
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(result?.data, { variant: "error" });
    }
  };

  const onOkHandler = async (serviceProvider, values) => {
    dispatch(startLoading());
    OpenPdfViewer("Terms & Conditions", serviceProvider, values);
    try {
      await updateServiceProviderTncProviderDate(
        serviceProvider?.serviceProvider?.id
      );
    } catch (error) {
      console.error("An error occurred:", error);
    }
    dispatch(stopLoading());
  };

  const operationalHoursModalHanldler = () => {
    dispatch(openOperationalHoursModal());
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    setAlertContent("");
  };

  const operationalHoursModalCloseHandler = () => {
    dispatch(closeOperationalHoursModal());
  };

  return (
    <>
      <Formik
        initialValues={{
          namePrefix: serviceProvider?.serviceProvider?.namePrefix || "",
          nameSuffix: serviceProvider?.serviceProvider?.nameSuffix || "",
          firstName: serviceProvider?.serviceProvider?.firstName || "",
          lastName: serviceProvider?.serviceProvider?.lastName || "",
          phone: serviceProvider?.serviceProvider?.phone || "",
          countryCode:
            serviceProvider?.serviceProvider.countryCode || "IN (+91)",
          email: serviceProvider?.serviceProvider?.email || "",
          title: serviceProvider?.serviceProvider?.title || "",
          businessName: serviceProvider?.serviceProvider?.businessName || "",
          serviceCategory:
            serviceProvider?.serviceProvider?.categories?.map(
              (category) => category.id
            ) || [],
          image: serviceProvider?.image,
          termsPdf: tncPdfUrl || null,
          approvalPdf: homeBasedPdfUrl || null,
          deleteTnc: false,
          deleteHomeApproval: false,
          preferredLanguage:
            serviceProvider?.serviceProvider?.preferredLanguage?.id || "",
          location: serviceProvider?.serviceProvider?.address,
          isResidentProvider:
            serviceProvider?.serviceProvider?.residentProvider !== null
              ? true
              : false,
          residentProvider:
            serviceProvider?.serviceProvider?.residentProvider !== null
              ? serviceProvider?.serviceProvider?.residentProvider?.id
              : 0,
          homeBased: serviceProvider?.serviceProvider?.homeBased || false,
          deliveryStatus: serviceProvider?.serviceProvider?.homeVisit
            ? true
            : serviceProvider?.serviceProvider?.deliverable
              ? false
              : false,
          description: serviceProvider?.serviceProvider?.description || "",
          privateNotes: serviceProvider?.serviceProvider?.privateNotes || "",
          referredBy: serviceProvider?.referredBy?.resident?.id || null,
          referAnonymously: serviceProvider?.referredBy?.anonymous || false,
          referrerAvailedService: serviceProvider?.referredBy?.availedService || false,
          serviceLocation:
            serviceProvider?.serviceProvider?.serviceLocation || "Premises",
          latitude: serviceProvider?.serviceProvider?.address?.latitude,
          longitude: serviceProvider?.serviceProvider?.address?.longitude,
          address: serviceProvider?.serviceProvider?.address?.formattedAddress,
          locationUrl: serviceProvider?.serviceProvider?.address?.locationUrl,
          homeBasedApprovedAt:
            serviceProvider?.serviceProvider?.homeBasedApprovedAt,
          homeBasedApproved:
            serviceProvider?.serviceProvider?.homeBasedApproved,
          serviceStartPrice:
            serviceProvider?.serviceProvider?.serviceStartPrice,
          serviceEndPrice: serviceProvider?.serviceProvider?.serviceEndPrice,
          businessHours: spBusinessHours,
        }}
        enableReinitialize
        validationSchema={Validator(phoneData)}
        onSubmit={async (values) => {
          const onSaveHandler = () => {
            setConfirmationMessage(HOMEBASED_MESSAGE);
            setSaveModal(true);
            dispatch(openConfirmationModal());
          };
          if (
            values.approvalPdf === null &&
            values.isResidentProvider &&
            values.homeBased &&
            !values.homeBasedApprovedAt
          ) {
            onSaveHandler();
          } else {
            const serviceLocationValues = values.serviceLocation;
            if (serviceLocationValues === "Premises") {
              values.serviceLocation = "Premises";
            } else if (serviceLocationValues === "HOME") {
              values.serviceLocation = "Premises";
            } else {
              values.serviceLocation = serviceLocationValues;
            }

            values.doorDelivery =
              values.serviceLocation === "Premises"
                ? false
                : !values.deliveryStatus
                  ? true
                  : false;
            values.deliverable =
              serviceLocationValues === "Premises" ? false : true;
            values.homeVisit =
              values.serviceLocation === "Premises"
                ? false
                : values.deliveryStatus
                  ? true
                  : false;
            values.residentProvider =
              values.residentProvider && values.residentProvider !== 0
                ? values.residentProvider
                : null;
            const location = {
              formattedAddress: values.address,
              locationUrl: values.locationUrl,
              longitude: values.longitude,
              latitude: values.latitude,
              locality: values.address,
            };
            if (values?.businessName && values?.businessName?.length === 0) {
              values.businessName = null;
            }
            if (values.email.length === 0) {
              values.email = null;
            }
            values.location = location;
            if (spBusinessHours) {
              values.businessHours = spBusinessHours;
            } else {
              values.businessHours = null;
            }
            values.serviceLocation = serviceLocationValues.toUpperCase();
            dispatch(startLoading());
            const result = await updateServiceProvider(id, values);
            if (result.success) {
              if (values?.termsPdf) {
                const pdfResponse = await uploadTncPdf(
                  values.termsPdf,
                  id,
                  approvedById,
                  false
                );
                if (pdfResponse.success) {
                  dispatch(stopLoading());
                  enqueueSnackbar(result?.data, {
                    variant: "success",
                  });
                } else {
                  enqueueSnackbar(pdfResponse?.data, { variant: "error" });
                }
              }
              if (values?.approvalPdf) {
                const pdfResponse = await uploadApprovalPdf(
                  values.approvalPdf,
                  id,
                  approvedById
                );
                if (pdfResponse.success) {
                  dispatch(stopLoading());
                  enqueueSnackbar(result?.data, {
                    variant: "success",
                  });
                } else {
                  enqueueSnackbar(pdfResponse?.data, { variant: "error" });
                }
              }
              if (typeof values?.image === "string") {
                dispatch(stopLoading());
                enqueueSnackbar(result?.data, {
                  variant: "success",
                });
              } else if (values.image === null) {
                const response = await deleteServiceProviderImage(id);
                if (response.success) {
                  dispatch(stopLoading());
                  enqueueSnackbar(result?.data, {
                    variant: "success",
                  });
                } else {
                  enqueueSnackbar(result?.data, {
                    variant: "success",
                  });
                }
              } else {
                const response = await uploadImage(values.image, id);
                if (response.success) {
                  dispatch(stopLoading());
                  enqueueSnackbar(result?.data, {
                    variant: "success",
                  });
                } else {
                  enqueueSnackbar(response?.data, { variant: "error" });
                }
              }
              navigate(`/admin/service-provider/all`);
            } else {
              dispatch(stopLoading());
              enqueueSnackbar(result?.data, { variant: "error" });
            }
          }
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          handleBlur,
          errors,
          touched,
          setFieldError,
        }) => {
          const handleBusinessNameChange = async (value) => {
            setFieldValue("businessName", value);
            const response = await fetchByBusinessName(value);
            if (response.success && response.data) {
              setBusinessNameData(response.data);
            } else {
              setBusinessNameData([]);
            }
          };

          const onCountryCodeChanged = (countryCode) => {
            setFieldValue("countryCode", countryCode.target.value);
            setSelectedCountryCode(countryCode.target.value);
          };

          const handleReferralHelper = (value, type) => {
            const result = value.target.checked;
            if (values?.referredBy !== null) {
              if (type === "referAnonymously") {
                setFieldValue("referAnonymously", result);
              } else {
                setFieldValue("referrerAvailedService", result);
              }
            } else {
              setAlertContent(
                "Please select a resident in the Referred By field to continue"
              );
              setAlertOpen(true);
            }
          };

          const validatePrices = (name, value) => {
            const newValues = { ...values, [name]: value };
            const startPrice = parseFloat(newValues.serviceStartPrice || 0);
            const endPrice = parseFloat(newValues.serviceEndPrice || 0);

            let newErrors = { ...priceErrors };

            if (startPrice && endPrice && startPrice >= endPrice) {
              newErrors = {
                ...newErrors,
                serviceStartPrice: "Start price must be less than end price.",
                serviceEndPrice: "End price must be greater than start price.",
              };
            } else {
              delete newErrors.serviceStartPrice;
              delete newErrors.serviceEndPrice;
            }

            setPriceErrors(newErrors);
          };

          const handlePhoneNumberChange = async (value) => {
            setFieldValue("phone", value);
            if (value?.length === 10) {
              const response = await checkServiceExistsByPhoneNumber(value);
              if (response.success && response.data) {
                if (
                  serviceProvider?.serviceProvider?.phone ===
                  response.data?.serviceProvider?.phone
                ) {
                  setPhoneData([]);
                } else if (response.data?.deleted) {
                  setFieldError("phone", "Phone number already exists, but in suspended status");
                  setPhoneData([{ ...response.data, phone: value }]);
                } else {
                  setFieldError("phone", "Phone number already exist");
                  setPhoneData([{ ...response.data, phone: value }]);
                }
              } else {
                setPhoneData([]);
                setFieldError("phone", "");
              }
            } else {
              setPhoneData([]);
              setFieldError("phone", "");
            }
          };

          const handleSelectResidentProvider = (newValue) => {
            setFieldValue("residentProvider", newValue);
            setResidentProviderValue(newValue);
          };

          const handleIsSelectResidentProvider = (newValue) => {
            const isResident = newValue.target.value === "true";
            setFieldValue("isResidentProvider", isResident);
            setResides(isResident);

            if (!isResident) {
              setFieldValue("residentProvider", 0);
              setResidentProviderValue(0);
              setFieldValue("homeBased", false);
              setFieldValue("isResidentProvider", false);
            }
          };

          const handleIsHomeBased = (newValue) => {
            if (newValue.target.value === "false") {
              setFieldValue("homeBased", false);
            } else {
              setFieldValue("homeBased", true);
            }
          };

          const handleServiceLocation = (e, location) => {
            let newValue;
            const currentValue = values?.serviceLocation;
            if (e.target.checked) {
              if (currentValue && currentValue !== location) {
                newValue = "Both";
              } else {
                newValue = location;
              }
            } else {
              if (currentValue === "Both") {
                newValue = SERVICE_LOCATION.find((n) => n !== location);
              } else {
                newValue = "";
              }
            }

            if (newValue === "Premises") {
              setFieldValue("deliveryStatus", false);
            }

            handleChange({
              target: {
                name: "serviceLocation",
                value: newValue,
              },
            });
          };

          const onApproveHandler = () => {
            setConfirmationMessage(HOMEBASED_MESSAGE);
            setTncModal(true);
            dispatch(openConfirmationModal());
          };

          const sendTnC = () => {
            if (
              (values.approvalPdf === null && !values.homeBased) ||
              (values.homeBased && values.approvalPdf)
            ) {
              onResend(values);
            } else {
              onApproveHandler();
            }
          };

          const debouncedSendTnC = debounce(() => {
            sendTnC();
          }, 1000);

          const editServiceProvider = async () => {
            setSaveModal(false);
            dispatch(startLoading());
            const serviceLocationValues = values.serviceLocation;
            if (serviceLocationValues === "Premises") {
              values.serviceLocation = "Premises";
            } else if (serviceLocationValues === "HOME") {
              values.serviceLocation = "Premises";
            } else {
              values.serviceLocation = serviceLocationValues;
            }

            values.doorDelivery =
              values.serviceLocation === "Premises"
                ? false
                : !values.deliveryStatus
                  ? true
                  : false;
            values.deliverable =
              serviceLocationValues === "Premises" ? false : true;
            values.homeVisit =
              values.serviceLocation === "Premises"
                ? false
                : values.deliveryStatus
                  ? true
                  : false;
            values.residentProvider =
              values.residentProvider && values.residentProvider !== 0
                ? values.residentProvider
                : null;
            const location = {
              formattedAddress: values.address,
              locationUrl: values.locationUrl,
              longitude: values.longitude,
              latitude: values.latitude,
              locality: values.address,
            };
            if (values?.businessName && values?.businessName?.length === 0) {
              values.businessName = null;
            }
            if (values.email.length === 0) {
              values.email = null;
            }
            values.location = location;
            if (spBusinessHours) {
              values.businessHours = spBusinessHours;
            } else {
              values.businessHours = null;
            }
            values.serviceLocation = serviceLocationValues.toUpperCase();
            const result = await updateServiceProvider(id, values);
            if (result.success) {
              if (values?.termsPdf) {
                const pdfResponse = await uploadTncPdf(
                  values.termsPdf,
                  id,
                  approvedById,
                  false
                );
                if (pdfResponse.success) {
                  dispatch(stopLoading());
                  enqueueSnackbar(result?.data, {
                    variant: "success",
                  });
                } else {
                  enqueueSnackbar(pdfResponse?.data, { variant: "error" });
                }
              }
              if (values?.approvalPdf) {
                const pdfResponse = await uploadApprovalPdf(
                  values.approvalPdf,
                  id,
                  approvedById
                );
                if (pdfResponse.success) {
                  dispatch(stopLoading());
                  enqueueSnackbar(result?.data, {
                    variant: "success",
                  });
                } else {
                  enqueueSnackbar(pdfResponse?.data, { variant: "error" });
                }
              }

              if (
                !values?.approvalPdf &&
                values.homeBased &&
                !serviceProvider?.serviceProvider?.homeBasedApproved
              ) {
                const result = await approveServiceProvider(
                  serviceProvider?.serviceProvider?.id,
                  approvedById
                );
                if (result.success) {
                  dispatch(stopLoading());
                } else {
                  dispatch(stopLoading());
                  enqueueSnackbar(result?.data, { variant: "error" });
                }
              }

              if (typeof values?.image === "string") {
                dispatch(stopLoading());
                enqueueSnackbar(result?.data, {
                  variant: "success",
                });
              } else if (values.image === null) {
                const response = await deleteServiceProviderImage(id);
                if (response.success) {
                  dispatch(stopLoading());
                  enqueueSnackbar(result?.data, {
                    variant: "success",
                  });
                } else {
                  enqueueSnackbar(result?.data, {
                    variant: "success",
                  });
                }
              } else {
                const response = await uploadImage(values.image, id);
                if (response.success) {
                  dispatch(stopLoading());
                  enqueueSnackbar(result?.data, {
                    variant: "success",
                  });
                  navigate(`/admin/service-provider/all`);
                } else {
                  enqueueSnackbar(response?.data, { variant: "error" });
                  navigate(`/admin/service-provider/all`);
                }
              }
              navigate(`/admin/service-provider/all`);
            } else {
              dispatch(stopLoading());
              enqueueSnackbar(result?.data, { variant: "error" });
            }
          };

          return (
            <>
              <Paper
                className="paper__container"
                sx={{ height: AUTO }}
                elevation={4}
              >
                <form noValidate onSubmit={handleSubmit} className="form">
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Stack spacing={3}>
                        <Breadcrumbs
                          separator={<NavigateNextIcon />}
                          aria-label="breadcrumb"
                        >
                          {breadcrumbs}
                        </Breadcrumbs>
                      </Stack>
                    </Grid>
                    <Grid container spacing={2} marginLeft={0.5} marginTop={1}>
                      <Grid item xs={2}>
                        <FormControl
                          size={SMALL}
                          fullWidth
                          error={
                            touched.namePrefix && Boolean(errors.namePrefix)
                          }
                        >
                          <InputLabel id="name-prefix-label" required>
                            Name Prefix
                          </InputLabel>
                          <Select
                            labelId="name-prefix-label"
                            label="Name Prefix"
                            value={values?.namePrefix || ""}
                            onChange={handleChange}
                            name="namePrefix"
                            onBlur={handleBlur}
                          >
                            {NAME_PREFIX_TYPES.map((namePrefixType) => (
                              <MenuItem
                                key={namePrefixType}
                                value={namePrefixType}
                              >
                                {namePrefixType}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.namePrefix && errors.namePrefix && (
                            <FormHelperText>{errors.namePrefix}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          label="Name Suffix"
                          fullWidth
                          size={SMALL}
                          value={values?.nameSuffix}
                          onChange={handleChange}
                          name="nameSuffix"
                        ></TextField>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          required
                          label="First Name"
                          fullWidth
                          size={SMALL}
                          value={values?.firstName}
                          onChange={handleChange}
                          name="firstName"
                          onBlur={handleBlur}
                          error={touched.firstName && Boolean(errors.firstName)}
                          helperText={touched.firstName && errors.firstName}
                        ></TextField>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          required
                          label="Last Name"
                          fullWidth
                          size={SMALL}
                          value={values?.lastName}
                          onChange={handleChange}
                          name="lastName"
                          onBlur={handleBlur}
                          error={touched.lastName && Boolean(errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                        ></TextField>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      marginLeft={0.5}
                      marginTop={0.5}
                    >
                      <Grid item xs={4}>
                        <PhoneAutocomplete
                          selectedPhoneData={selectedPhoneData}
                          handlePhoneNumberChange={handlePhoneNumberChange}
                          setSelectedPhoneData={setSelectedPhoneData}
                          phoneData={phoneData}
                          values={values}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          countryCodes={countryCodes}
                          onCountryCodeChanged={onCountryCodeChanged}
                          selectedCountryCode={selectedCountryCode}
                          onPaste={(e) => e.stopPropagation()}
                          onCopy={(e) => e.stopPropagation()}
                          onCut={(e) => e.stopPropagation()}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Email"
                          fullWidth
                          size={SMALL}
                          value={values?.email}
                          onChange={handleChange}
                          name="email"
                          onBlur={handleBlur}
                        // error={touched.email && Boolean(errors.email)}
                        // helperText={touched.email && errors.email}
                        ></TextField>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          required
                          label="Title"
                          fullWidth
                          size={SMALL}
                          value={values?.title}
                          onChange={handleChange}
                          name="title"
                          onBlur={handleBlur}
                          error={touched.title && Boolean(errors.title)}
                          helperText={touched.title && errors.title}
                        ></TextField>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      marginLeft={0.5}
                      marginTop={0.3}
                    >
                      <Grid item xs={4}>
                        <Autocomplete
                          size="small"
                          disableCloseOnSelect
                          multiple
                          options={[
                            ...values?.serviceCategory,
                            ...serviceCategories
                              ?.map((serviceCategory) => serviceCategory.id)
                              ?.filter(
                                (option) =>
                                  !values?.serviceCategory?.includes(option)
                              ),
                          ]}
                          getOptionLabel={(option) => {
                            const category = serviceCategories.find(
                              (s) => s.id === option
                            );
                            return category?.name || "";
                          }}
                          value={values?.serviceCategory}
                          onChange={(_, newValue) => {
                            setFieldValue("serviceCategory", newValue);
                          }}
                          onInputChange={(_, newValue) => {
                            setFieldValue("serviceCategory", newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Service Category"
                              name="serviceCategory"
                              required
                              fullWidth
                              size={SMALL}
                              value={values?.serviceCategory}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.serviceCategory &&
                                Boolean(errors.serviceCategory)
                              }
                              helperText={
                                touched.serviceCategory &&
                                errors.serviceCategory
                              }
                            />
                          )}
                          renderTags={(selected, getTagProps) => {
                            const displayedTags = selected.slice(0, 1);
                            const hiddenTagsCount =
                              selected.length - displayedTags.length;
                            return (
                              <Box display="flex" alignItems="center">
                                {displayedTags.map((value, index) => (
                                  <Chip
                                    size="small"
                                    key={value}
                                    label={
                                      serviceCategories.find(
                                        (serviceCategory) =>
                                          serviceCategory.id === value
                                      )?.name
                                    }
                                    {...getTagProps({ index })}
                                  />
                                ))}
                                {hiddenTagsCount > 0 && (
                                  <Box ml={1}>+{hiddenTagsCount}</Box>
                                )}
                              </Box>
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <BusinessNameAutocomplete
                          selectedBusinessNameData={selectedBusinessNameData}
                          handleBusinessNameChange={handleBusinessNameChange}
                          setSelectedBusinessNameData={
                            setSelectedBusinessNameData
                          }
                          businessNameData={businessNameData || ""}
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl
                          size={SMALL}
                          fullWidth
                          error={
                            touched.preferredLanguage &&
                            Boolean(errors.preferredLanguage)
                          }
                        >
                          <InputLabel id="preferred-language-label" required>
                            Preferred Language
                          </InputLabel>
                          <Select
                            labelId="preferred-language-label"
                            label="Preferred Language"
                            value={values?.preferredLanguage || ""}
                            onChange={handleChange}
                            name="preferredLanguage"
                            onBlur={handleBlur}
                          >
                            {preferredLanguage.map((language) => (
                              <MenuItem key={language?.id} value={language?.id}>
                                {language?.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.preferredLanguage &&
                            errors.preferredLanguage && (
                              <FormHelperText>
                                {errors.preferredLanguage}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      marginLeft={0.5}
                      marginTop={0.3}
                    >
                      <Grid item xs={4}>
                        <Grid>
                          <ImagePicker
                            from={SERVICE_PROVIDER_EDIT}
                            setFieldValue={setFieldValue}
                            defaultImage={values?.image}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                          />
                        </Grid>
                        <Grid item>
                          <Typography sx={{ fontStyle: "italic", fontSize: 15 }}>
                            <IconButton size="small">
                              <InfoIcon fontSize="small" color="warning" />
                            </IconButton> Maximum file size allowed is 5MB.
                          </Typography>

                        </Grid>
                        {touched.image && errors.image && (
                          <Grid>
                            <FormHelperText sx={{ color: RED }}>
                              Image is required
                            </FormHelperText>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item md={8}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              label="Latitude"
                              placeholder="Enter Latitude"
                              fullWidth
                              type="number"
                              inputProps={{
                                step: "any",
                                min: -180,
                                max: 180,
                              }}
                              required
                              size={SMALL}
                              value={values?.latitude}
                              onChange={handleChange}
                              name="latitude"
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={
                                touched.latitude && Boolean(errors.latitude)
                              }
                              helperText={touched.latitude && errors.latitude}
                              sx={{
                                "& input[type=number]": {
                                  MozAppearance: "textfield",
                                },
                                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                                {
                                  WebkitAppearance: "none",
                                  margin: 0,
                                },
                              }}
                            />
                          </Grid>

                          {/* Longitude */}
                          <Grid item xs={6}>
                            <TextField
                              label="Longitude"
                              placeholder="Enter Longitude"
                              fullWidth
                              type="number"
                              inputProps={{
                                step: "any",
                                min: -180,
                                max: 180,
                              }}
                              required
                              size={SMALL}
                              value={values?.longitude}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onChange={handleChange}
                              name="longitude"
                              onBlur={handleBlur}
                              sx={{
                                "& input[type=number]": {
                                  MozAppearance: "textfield",
                                },
                                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                                {
                                  WebkitAppearance: "none",
                                  margin: 0,
                                },
                              }}
                              error={
                                touched.longitude && Boolean(errors.longitude)
                              }
                              helperText={touched.longitude && errors.longitude}
                            />
                          </Grid>

                          {/* Left column: Location URL and Set Business Hours (stacked) */}
                          <Grid
                            item
                            xs={6}
                            container
                            spacing={2}
                            direction="column"
                          >
                            <Grid item>
                              <TextField
                                label="Location URL"
                                placeholder="Enter Location URL"
                                fullWidth
                                required
                                value={values?.locationUrl || ""}
                                onChange={handleChange}
                                name="locationUrl"
                                onBlur={handleBlur}
                                size={SMALL}
                                error={
                                  touched.locationUrl &&
                                  Boolean(errors.locationUrl)
                                }
                                helperText={
                                  touched.locationUrl && errors.locationUrl
                                }
                              />
                            </Grid>
                            <Grid item textAlign={"center"} width={"100%"}>
                              <Tooltip
                                title="Click to set your business hours"
                                arrow
                              >
                                <Button
                                  onClick={operationalHoursModalHanldler}
                                  variant="contained"
                                  startIcon={<WorkHistoryOutlinedIcon />}
                                  sx={{
                                    textTransform: "capitalize",
                                    bgcolor: "#800020", // Example with Burgundy
                                    color: "#fff",
                                    "&:hover": {
                                      bgcolor: "#B22222", // Firebrick for the hover effect
                                    },
                                    borderRadius: "8px",
                                    padding: "8px 16px",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    width: "100%",
                                  }}
                                >
                                  Set Business Hours
                                </Button>
                              </Tooltip>
                            </Grid>
                          </Grid>

                          {/* Right column: Address */}
                          <Grid item xs={6}>
                            <TextField
                              label="Address"
                              placeholder="Enter Address"
                              fullWidth
                              multiline
                              minRows={4}
                              maxRows={4}
                              required
                              value={values?.address || ""}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue("address", e.target.value);
                              }}
                              name="address"
                              onBlur={handleBlur}
                              error={touched.address && Boolean(errors.address)}
                              helperText={touched.address && errors.address}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      marginLeft={0.5}
                      marginTop={0.3}
                    >
                      <Grid item md={4} marginTop={"16px"}>
                        <Autocomplete
                          options={residents.filter((r) => r.id)}
                          value={residents.find((r) => r.id === values.referredBy) || null}
                          onChange={(_, newValue) => {
                            if (newValue) {
                              setFieldValue("referredBy", newValue.id);
                            } else {
                              setFieldValue("referredBy", ""); 
                              setFieldValue("referAnonymously", false);
                              setFieldValue("referrerAvailedService", false);
                            }
                          }}

                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          getOptionLabel={(option) =>
                            option?.name
                              ? `${option.name} | ${option.apartment?.building?.name || ""} | ${option.apartment?.number || ""
                              }`
                              : ""
                          }
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Grid container alignItems="center">
                                <Grid item width={60} height={60} marginRight="20px">
                                  <img src={option?.image || avatar} alt="" width="100%" height="100%" />
                                </Grid>
                                <Grid item width="70%">
                                  <Typography variant="body2" fontWeight="bold" noWrap>
                                    {option?.name || "No Name"}
                                  </Typography>
                                  <Typography variant="body2">
                                    {option?.apartment?.building?.name || "No Building"}
                                  </Typography>
                                  <Typography variant="body2">
                                    {option?.apartment?.number || "No Number"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </li>
                          )}

                          size="small"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Referred By"
                              name="referredBy"
                              error={touched.referredBy && Boolean(errors.referredBy)}
                              helperText={touched.referredBy && errors.referredBy}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} marginLeft={1} marginTop={0.3}>
                      <Grid item xs={3}>
                        <FormControl>
                          <FormControlLabel
                            key={"referAnonymously"}
                            control={
                              <Checkbox
                                sx={{
                                  color: DEEP_OCEAN_BLUE,
                                }}
                                size={SMALL}
                                name="referAnonymously"
                                checked={values?.referAnonymously}
                                onChange={(value) =>
                                  handleReferralHelper(
                                    value,
                                    "referAnonymously"
                                  )
                                }
                              />
                            }
                            label={<Typography>Refer Anonymously</Typography>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={3} marginTop={2}>
                        <FormControl>
                          <FormControlLabel
                            key={"referrerAvailedService"}
                            control={
                              <Checkbox
                                sx={{
                                  color: DEEP_OCEAN_BLUE,
                                }}
                                disabled={values?.referredBy == null}
                                size={SMALL}
                                name="referrerAvailedService"
                                checked={values?.referrerAvailedService}
                                onChange={(value) =>
                                  handleReferralHelper(
                                    value,
                                    "referrerAvailedService"
                                  )
                                }
                              />
                            }
                            label={
                              <Typography>Referrer Availed Service</Typography>
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="Service Start Price"
                          placeholder="Enter the service starting price."
                          fullWidth
                          type="number"
                          size={SMALL}
                          value={values?.serviceStartPrice}
                          onChange={(e) => {
                            handleChange(e);
                            validatePrices(e.target.name, e.target.value);
                          }}
                          name="serviceStartPrice"
                          onBlur={handleBlur}
                          error={!!priceErrors?.serviceStartPrice}
                          helperText={priceErrors?.serviceStartPrice}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            onWheel: (e) => e.target.blur()
                          }}
                          sx={{
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                            "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                            {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          label="Service End Price"
                          placeholder="Enter the service ending price."
                          fullWidth
                          type="number"
                          size={SMALL}
                          value={values?.serviceEndPrice}
                          onChange={(e) => {
                            handleChange(e);
                            validatePrices(e.target.name, e.target.value);
                          }}
                          name="serviceEndPrice"
                          onBlur={handleBlur}
                          error={!!priceErrors?.serviceEndPrice}
                          helperText={priceErrors?.serviceEndPrice}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            onWheel: (e) => e.target.blur()
                          }}
                          sx={{
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                            "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                            {
                              WebkitAppearance: "none",
                              margin: 0,
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} marginLeft={1} marginTop={0.3}>
                      <Grid item md={6} marginBottom={10}>
                        <TextField
                          required
                          label="Description"
                          fullWidth
                          size={SMALL}
                          value={values.description || ""}
                          onChange={handleChange}
                          name="description"
                          multiline
                          minRows={5}
                          maxRows={5}
                          onBlur={handleBlur}
                          error={
                            touched.description && Boolean(errors.description)
                          }
                          helperText={touched.description && errors.description}
                        ></TextField>
                      </Grid>
                      <Grid item md={6} marginBottom={10}>
                        <TextField
                          label="Private Notes"
                          fullWidth
                          size={SMALL}
                          value={values.privateNotes || ""}
                          onChange={handleChange}
                          name="privateNotes"
                          multiline
                          minRows={5}
                          maxRows={5}
                        ></TextField>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} marginLeft={1} marginTop={0.3}>
                      {/* Service Location Section */}
                      <Grid item xs={2} marginTop={3}>
                        <Typography
                          color={TEXT_COLOR}
                          fontSize="1rem"
                          fontWeight="400"
                          paddingRight="10px"
                        >
                          Service Location
                          <span className="MuiFormLabel-asterisk"> *</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={3} marginTop={1} alignContent={"start"}>
                        <Grid container>
                          {SERVICE_LOCATION.map((location) => (
                            <Grid item xs={6} key={location} marginTop={1}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    sx={{ color: DEEP_OCEAN_BLUE }}
                                    size={SMALL}
                                    value={values?.serviceLocation}
                                    checked={
                                      values?.serviceLocation === location ||
                                      values?.serviceLocation === "Both"
                                    }
                                    onChange={(e) =>
                                      handleServiceLocation(e, location)
                                    }
                                    onBlur={handleBlur}
                                  />
                                }
                                label={<Typography>{location}</Typography>}
                              />
                            </Grid>
                          ))}
                        </Grid>
                        {touched.serviceLocation && errors.serviceLocation && (
                          <Grid item xs={6}>
                            <FormHelperText style={{ color: RED }}>
                              {errors.serviceLocation}
                            </FormHelperText>
                          </Grid>
                        )}
                      </Grid>
                      {/* Doorstep Service */}
                      {values?.serviceLocation &&
                        (values?.serviceLocation === "Home" ||
                          values?.serviceLocation === "Both") && (
                          <Grid item xs={5} marginTop={2}>
                            <Grid container>
                              <Grid item xs={5} marginTop={1}>
                                <Typography
                                  fontSize="1rem"
                                  fontWeight="400"
                                  paddingBottom="8px"
                                >
                                  Doorstep Service
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Switch
                                  name="deliveryStatus"
                                  checked={values?.deliveryStatus}
                                  onChange={handleChange}
                                  disabled={
                                    !values?.serviceLocation ||
                                    values?.serviceLocation === "Premises"
                                  }
                                />
                              </Grid>
                              <Grid item xs={4} marginTop={1}>
                                <Typography
                                  fontSize="1rem"
                                  fontWeight="400"
                                  paddingBottom="8px"
                                >
                                  Home visit
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                    </Grid>
                    <Grid container spacing={2} marginLeft={1} marginTop={0.3}>
                      {/* Resides in the Community */}
                      <Grid item xs={2} marginTop={1}>
                        <Typography>Resides in the Community</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl>
                          <RadioGroup
                            row
                            name="isResidentProvider"
                            onChange={handleIsSelectResidentProvider}
                          >
                            <FormControlLabel
                              value={true}
                              checked={values?.isResidentProvider}
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={false}
                              checked={!values?.isResidentProvider}
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {/* Home Based Services */}
                      {resides && (
                        <Grid container alignItems="center" marginLeft={2}>
                          <Grid item md={4}>
                            <Autocomplete
                              disabled={!values.isResidentProvider}
                              options={residents
                                .map((r) => r.id)
                                .filter((id) => residentProviderValue !== id)}
                              value={
                                residentProviderValue !== 0
                                  ? residentProviderValue
                                  : null
                              }
                              onChange={(_, newValue) => {
                                handleSelectResidentProvider(newValue);
                              }}
                              getOptionLabel={(option) => {
                                const resident = residents?.find(
                                  (r) => r.id === option
                                );
                                return `${resident?.name} | ${resident?.apartment?.building?.name} | ${resident?.apartment?.number}`;
                              }}
                              renderOption={(props, option) => {
                                const resident = residents?.find(
                                  (r) => r.id === option
                                );
                                return (
                                  <li {...props}>
                                    <Grid
                                      container
                                      alignItems="center"
                                      display="flex"
                                    >
                                      <Grid
                                        item
                                        width={60}
                                        height={60}
                                        marginRight={"20px"}
                                      >
                                        <img
                                          src={
                                            resident?.image
                                              ? resident?.image
                                              : avatar
                                          }
                                          alt=""
                                          width="100%"
                                          height="100%"
                                        />
                                      </Grid>
                                      <Grid item width="70%">
                                        <Grid
                                          container
                                          alignItems="flex-start"
                                          display="flex"
                                          flexDirection="column"
                                        >
                                          <Grid item width="100%">
                                            <Typography
                                              variant="body2"
                                              color={DEEP_OCEAN_BLUE}
                                              noWrap
                                              fontWeight="bold"
                                            >
                                              {resident?.name}
                                            </Typography>
                                          </Grid>
                                          <Grid item width="100%">
                                            <Typography variant="body2">
                                              {resident?.apartment?.building?.name}
                                            </Typography>
                                          </Grid>
                                          <Grid item width="100%">
                                            <Typography variant="body2">
                                              {resident?.apartment?.number}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </li>
                                );
                              }}
                              renderTags={(selected) => {
                                const resident = residents?.find(
                                  (r) => r.id === selected
                                );
                                return (
                                  <Chip
                                    label={
                                      <span className="autocomplete-single">
                                        {`${resident?.name} | ${resident?.apartment?.building?.name} | ${resident?.apartment?.number}`}
                                      </span>
                                    }
                                  />
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  disabled={!values.isResidentProvider}
                                  {...params}
                                  label="Resident info"
                                  name="residentProvider"
                                  error={
                                    values.isResidentProvider &&
                                    touched.residentProvider &&
                                    Boolean(errors.residentProvider)
                                  }
                                  helperText={
                                    values.isResidentProvider &&
                                    touched.residentProvider &&
                                    errors.residentProvider
                                  }
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      height: "47px",
                                    }
                                  }}
                                />
                              )}
                              size="small"
                            />
                          </Grid>
                          <Grid
                            container
                            xs={5}
                            // alignItems="center"
                            justifyContent="space-between"
                            // spacing={1}
                            marginLeft={5}
                            marginTop={1}
                          >
                            <Grid item xs={5} marginTop={1}>
                              <Typography>Home Based Services</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl>
                                <RadioGroup
                                  row
                                  name="homeBased"
                                  onChange={handleIsHomeBased}
                                >
                                  <FormControlLabel
                                    value={true}
                                    checked={resides && values?.homeBased}
                                    control={<Radio />}
                                    label="Yes"
                                    disabled={!resides}
                                  />
                                  <FormControlLabel
                                    value={false}
                                    checked={resides && !values?.homeBased}
                                    control={<Radio />}
                                    label="No"
                                    disabled={!resides}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid container spacing={2} marginLeft={1} marginTop={0.3}>
                      <Grid
                        item
                        xs={4}
                        className="Service__terms__details__container"
                        textAlign={"justify"}
                      >
                        <UploadPdf
                          from={"Terms"}
                          defaultImage={values?.termsPdf}
                          setFieldValue={setFieldValue}
                          homeBased={true}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className="Service__terms__details__container"
                        textAlign={"justify"}
                      >
                        <UploadPdf
                          from={"Approval"}
                          defaultImage={values?.approvalPdf}
                          setFieldValue={setFieldValue}
                          homeBased={values.homeBased}
                        />
                      </Grid>
                    </Grid>
                    <Grid>
                      <ScheduleFormModal
                        onCancelHandler={operationalHoursModalCloseHandler}
                        onOkHandler={(scheduleData) => {
                          setSpBusinessHours(scheduleData);
                          setFieldValue("businessHours", scheduleData);
                          operationalHoursModalCloseHandler();
                        }}
                        formValues={values.businessHours}
                      />
                    </Grid>
                    <Grid item xs={6} marginLeft={3} marginTop={2.5}>
                      {values.isResidentProvider &&
                        (residentProviderValue === null ||
                          residentProviderValue === 0) && (
                          <span className="form-note-label">
                            Note: Please select a resident to proceed.
                          </span>
                        )}
                    </Grid>
                    <Grid
                      item
                      md={12}
                      display={FLEX}
                      justifyContent={CENTER}
                      marginTop={5}
                    >
                      <Stack spacing={3} direction={ROW}>
                        <Button
                          type="submit"
                          variant="contained"
                          disabled={
                            Object.keys(priceErrors).length > 0 ||
                            (values.isResidentProvider &&
                              (residentProviderValue === null ||
                                residentProviderValue === 0))
                          }
                          className="submit__button"
                        >
                          Save
                        </Button>
                        {saveModal && (
                          <ConfirmationModal
                            message={confirmationMessage}
                            onOkHandler={editServiceProvider}
                            onCancelHandler={() => {
                              setSaveModal(false);
                            }}
                            cancelText="No"
                            okText="Yes"
                            from={SERVICE_PROVIDER_EDIT}
                          />
                        )}
                        {serviceProvider?.serviceProvider?.status ===
                          "Pending" && (
                            <>
                              <Button
                                disabled={
                                  values.approvalPdf !== null &&
                                  values.termsPdf !== null
                                }
                                type="button"
                                variant="contained"
                                className={"submit__button"}
                                onClick={debouncedSendTnC}
                              >
                                Send T&C
                              </Button>
                              {tncModal && (
                                <ConfirmationModal
                                  message={confirmationMessage}
                                  onOkHandler={() => onResend(values)}
                                  onCancelHandler={() => {
                                    setTncModal(false);
                                  }}
                                  cancelText="No"
                                  okText="Yes"
                                  from={SERVICE_PROVIDER_EDIT}
                                />
                              )}
                              <Button
                                type="button"
                                variant="contained"
                                className={"submit__button"}
                                onClick={() => {
                                  onOkHandler(serviceProvider, {
                                    language: "English",
                                  });
                                }}
                              >
                                Print T&C PDF
                              </Button>
                            </>
                          )}
                        {values.homeBased &&
                          !values.homeBasedApproved &&
                          values.homeBasedApprovedAt !== null && (
                            <>
                              {" "}
                              <Button
                                disabled={!values?.homeBased}
                                type="button"
                                variant="contained"
                                className={"submit__button"}
                                onClick={onApproveHandler}
                              >
                                Approve
                              </Button>
                            </>
                          )}
                      </Stack>
                    </Grid>
                  </Grid>
                  <Loader />
                </form>
                <Snackbar
                  open={alertOpen}
                  autoHideDuration={6000}
                  onClose={handleAlertClose}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert onClose={handleAlertClose} severity="error">
                    {alertContent}
                  </Alert>
                </Snackbar>
              </Paper>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default Edit;
