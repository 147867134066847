import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import {
  InputAdornment,
  OutlinedInput,
  Paper,
  Grid,
  Typography,
  ThemeProvider,
  FormControl,
  Switch,
  Button,
  Autocomplete,
  Box,
  Chip,
  TextField,
  Badge,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { handleInputChange } from "../../helpers/GetMultiselectFilterValues";
import { useNavigate } from "react-router-dom";
import "./Resident.scss";
import ConfirmationModal from "../Common/ConfirmationModal";
import { deleteResident, fetchAllResidents } from "../../services/resident";
import { fetchAllResidentStatus } from "../../services/resident-status";
import DataTable from "../Common/DataTable";
import { useDispatch } from "react-redux";
import {
  openConfirmationModal,
  openDeleteReasonModal,
  startLoading,
  stopLoading,
} from "../../redux/Slices/CommonSlice";
import Loader from "../Common/Loader";
import {
  ALL,
  CENTER,
  FLEX,
  FLEX_END,
  REGISTERED,
  REINSTATE_MESSAGE,
  RESIDENT_LIST,
  SMALL,
  START,
} from "../../utils/constants";
import { enqueueSnackbar } from "notistack";
import DeleteReasonModal from "../Common/DeleteReasonModal";
import { theme } from "../Common/CommonStyles";
import { fetchBuildingForCommunity } from "../../services/building";
import { residentCsvReport } from "../../services/csvReport";

const List = () => {
  const initialFilterValues = useMemo(() => {
    const storedValues = localStorage.getItem("residentFilterValues");
    return storedValues
      ? JSON.parse(storedValues)
      : {
          status: [ALL],
          deleteStatus: false,
          buildingName: [ALL],
        };
  }, []);

  const navigate = useNavigate();
  const [residents, setResidents] = useState([]);
  const [statuses, setStatuses] = useState([ALL]);
  const [buildings, setBuildings] = useState([]);
  const [deleteResidentId, setDeleteResidentId] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const [sortBy, setSortBy] = useState("asc");
  const [sortColumn, setSortColumn] = useState("Name");
  const [isUserRequestOptionAvialable, setIsUserRequestOptionAvialable] =
    useState(false);
  const filterValuesRef = useRef(filterValues);
  const searchValuesRef = useRef(searchValue);
  const rowsPerPageRef = useRef(rowsPerPage);
  const dispatch = useDispatch();
  const isInitialRender = useRef(true);

  useEffect(() => {
    setPage(0);
    localStorage.setItem(
      "residentFilterValues",
      JSON.stringify({
        status: filterValues.status,
        deleteStatus: filterValues.deleteStatus,
        buildingName: filterValues.buildingName,
      })
    );
  }, [filterValues]);

  const deleteHandleChange = (e) => {
    setFilterValues((prevState) => ({
      ...prevState,
      deleteStatus: e.target.checked,
    }));
  };

  useEffect(() => {
    const fetchBuildings = async () => {
      dispatch(startLoading());
      try {
        const buildingsResponse = await fetchBuildingForCommunity(1);
        if (buildingsResponse.success && buildingsResponse.data) {
          setBuildings(buildingsResponse.data);
        } else {
          enqueueSnackbar(buildingsResponse.data, {
            variant: "error",
          });
        }
      } catch (error) {
        enqueueSnackbar("Failed to fetch buildings.", { variant: "error" });
      } finally {
        dispatch(stopLoading());
      }
    };
    fetchBuildings();
  }, [dispatch]);

  const sortOrderHandler = (header) => {
    setSortBy((prevSortBy) => (prevSortBy === "asc" ? "desc" : "asc"));
    setSortColumn(header);
  };

  useEffect(() => {
    setPage(0);
    filterValuesRef.current = filterValues;
  }, [filterValues]);

  useEffect(() => {
    searchValuesRef.current = searchValue;
  }, [searchValue]);

  useEffect(() => {
    rowsPerPageRef.current = rowsPerPage;
  }, [rowsPerPage]);

  const fetchData = useCallback(
    async (pageVal, sortColumnValue, sortByValue) => {
      dispatch(startLoading());
      try {
        const response = await fetchAllResidents(
          pageVal || 0,
          rowsPerPage,
          searchValuesRef.current,
          {
            status: filterValues.status,
            deleteStatus: filterValues.deleteStatus,
            buildingName: filterValues.buildingName,
          },
          sortColumnValue || "Name",
          sortByValue || "asc"
        );
        if (response.success && response.data?.residents) {
          setTotalRecords(response?.data?.totalRecords || 0);
          setResidents(response?.data?.residents);
        } else {
          enqueueSnackbar(response.data, { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("Failed to fetch residents.", { variant: "error" });
      } finally {
        dispatch(stopLoading());
      }
    },
    [rowsPerPage, dispatch, filterValues]
  );

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    const fetchResidents = async () => {
      setPage(0);
      dispatch(startLoading());
      try {
        const response = await fetchAllResidents(
          0,
          rowsPerPageRef.current,
          searchValue,
          {
            status: filterValuesRef.current.status,
            deleteStatus: filterValuesRef.current.deleteStatus,
            buildingName: filterValuesRef.current.buildingName,
          },
          sortColumn,
          sortBy
        );
        if (response.success && response.data?.residents) {
          setTotalRecords(response?.data?.totalRecords || 0);
          setResidents(response?.data?.residents);
        } else {
          enqueueSnackbar(response.data, { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("Failed to fetch residents.", { variant: "error" });
      } finally {
        dispatch(stopLoading());
      }
    };

    const debouncer = setTimeout(() => {
      fetchResidents();
    }, 500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [searchValue, dispatch, sortBy, sortColumn]);

  const getResidentStatus = useCallback(async () => {
    try {
      const response = await fetchAllResidentStatus();
      if (response.success && response.data) {
        const statusReponse = response.data;
        setStatuses(statusReponse);
      } else {
        enqueueSnackbar(response.data, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Failed to fetch resident statuses.", {
        variant: "error",
      });
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    getResidentStatus();
  }, [getResidentStatus]);

  const deleteHandler = async (values) => {
    dispatch(startLoading());
    try {
      const response = await deleteResident(
        deleteResidentId,
        values?.reason,
        values?.userRequested
      );
      await fetchData(page);
      if (response.success && response.data) {
        enqueueSnackbar(response.data.message, {
          variant: response.data.data.isDuplicatePhoneNumber
            ? "error"
            : "success",
        });
      } else {
        enqueueSnackbar(response.data, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Failed to delete resident.", { variant: "error" });
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData(newPage, sortColumn, sortBy);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    rowsPerPageRef.current = newRowsPerPage;
    setPage(0);
  };

  const onView = (resident) => {
    navigate(`/admin/resident/view/${resident.id}`);
  };

  const onEdit = (resident) => {
    navigate(`/admin/resident/edit/${resident.id}`);
  };

  const onDelete = (resident) => {
    setDeleteResidentId(resident.id);
    if (REGISTERED === resident?.residentStatus?.name) {
      setIsUserRequestOptionAvialable(true);
    } else {
      setIsUserRequestOptionAvialable(false);
    }
    if (resident?.deleted) {
      setConfirmationMessage(REINSTATE_MESSAGE);
      dispatch(openConfirmationModal());
    } else {
      dispatch(openDeleteReasonModal());
    }
  };

  const clearHandler = () => {
    setFilterValues({
      status: [ALL],
      deleteStatus: false,
      buildingName: [ALL],
    });
    setSearchValue("");
  };

  const downloadHandler = async () => {
    try {
      const response = await residentCsvReport(
        searchValue,
        filterValues.status,
        filterValues.deleteStatus,
        sortColumn,
        sortBy,
        filterValues.buildingName
      );
      if (response.data) {
        const fileName = "Resident-report.csv";
        const file = new Blob([response.data], { type: "application/csv" });
        const url = window.URL.createObjectURL(file);
        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = url;
        anchor.click();
        window.URL.revokeObjectURL(url);
      } else {
        enqueueSnackbar("Failed to download CSV.", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Failed to download CSV.", { variant: "error" });
    }
  };

  // Memoized filtered status options
  const statusOptions = useMemo(() => {
    if (!filterValues.status) return [ALL];
    const selectedStatus = filterValues.status.filter(
      (selectedValue) => selectedValue !== ALL
    );
    const availableStatuses = statuses
      ? statuses.map((status) => status.id)
      : [];
    const additionalStatuses = availableStatuses.filter(
      (option) => !filterValues.status.includes(option) && option !== ALL
    );
    return [ALL, ...selectedStatus, ...additionalStatuses];
  }, [filterValues.status, statuses]);

  // Memoized filtered building options
  const buildingOptions = useMemo(() => {
    if (!filterValues.buildingName) return [ALL];
    const selectedBuildings = filterValues.buildingName.filter(
      (selectedValue) => selectedValue !== ALL
    );
    const availableBuildings = buildings
      ? buildings.map((building) => building.id)
      : [];
    const additionalBuildings = availableBuildings.filter(
      (option) => !filterValues.buildingName.includes(option) && option !== ALL
    );
    return [ALL, ...selectedBuildings, ...additionalBuildings];
  }, [filterValues.buildingName, buildings]);

  const fetcResidentName = (row) => {
    return (
      <Grid container>
        {row?.deleted ? (
          <p>
            &nbsp;
            <Badge
              color="error"
              overlap="circular"
              variant="dot"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            ></Badge>
            &nbsp; &nbsp;
            <span>
              {!row.firstName && !row.lastName
                ? "-"
                : `${row?.firstName ? row?.firstName : ""} ${
                    row?.lastName ? row?.lastName : ""
                  }`}
            </span>
          </p>
        ) : (
          <>
            {!row.firstName && !row.lastName
              ? "-"
              : `${row?.firstName ? row?.firstName : ""} ${
                  row?.lastName ? row?.lastName : ""
                }`}
          </>
        )}
      </Grid>
    );
  };

  return (
    <Paper className="container" elevation={4}>
      <Grid container spacing={2} paddingBottom={2} className="Resident__List">
        <Grid item md={6}>
          <OutlinedInput
            fullWidth
            placeholder="Search by name, phone no., apt. no., building name"
            size={SMALL}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            startAdornment={
              <InputAdornment position={START}>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item md={6}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            options={statusOptions}
            getOptionLabel={(option) => {
              const status = [{ id: ALL, name: ALL }, ...statuses].find(
                (s) => s.id === option
              );
              return status?.name || "";
            }}
            value={filterValues?.status}
            onChange={(_, newValue) => {
              let event = {
                target: {
                  value: newValue,
                },
              };

              handleInputChange(
                event,
                "status",
                setFilterValues,
                filterValues,
                () => {},
                ALL
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Status" name="status" />
            )}
            renderTags={(selected, getTagProps) => {
              const displayedTags = selected.slice(0, 3);
              const hiddenTagsCount = selected.length - displayedTags.length;
              return (
                <Box display="flex" alignItems="center">
                  {displayedTags.map((value, index) => (
                    <Chip
                      size="small"
                      key={value}
                      label={
                        value === ALL
                          ? ALL
                          : statuses.find((status) => status.id === value)?.name
                      }
                      {...getTagProps({ index })}
                    />
                  ))}
                  {hiddenTagsCount > 0 && <Box ml={1}>+{hiddenTagsCount}</Box>}
                </Box>
              );
            }}
          />
        </Grid>
        <Grid item md={6}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            options={buildingOptions}
            getOptionLabel={(option) => {
              const building = [{ id: ALL, name: ALL }, ...buildings].find(
                (s) => s.id === option
              );
              return building?.name || "";
            }}
            value={filterValues?.buildingName}
            onChange={(_, newValue) => {
              let event = {
                target: {
                  value: newValue,
                },
              };

              handleInputChange(
                event,
                "buildingName",
                setFilterValues,
                filterValues,
                () => {},
                ALL
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Building Name" name="building" />
            )}
            renderTags={(selected, getTagProps) => {
              const displayedTags = selected.slice(0, 3);
              const hiddenTagsCount = selected.length - displayedTags.length;
              return (
                <Box display="flex" alignItems="center">
                  {displayedTags.map((value, index) => (
                    <Chip
                      size="small"
                      key={value}
                      label={
                        value === ALL
                          ? ALL
                          : buildings.find((building) => building.id === value)
                              ?.name
                      }
                      {...getTagProps({ index })}
                    />
                  ))}
                  {hiddenTagsCount > 0 && <Box ml={1}>+{hiddenTagsCount}</Box>}
                </Box>
              );
            }}
          />
        </Grid>
        <Grid item md={4} xl={2}>
          <ThemeProvider theme={theme}>
            <FormControl
              size={SMALL}
              fullWidth
              className="ServiceProviderCreate__switch__container"
            >
              <Typography className="Deleted__Toggle">Active</Typography>
              <Switch
                name="deleted"
                checked={filterValues?.deleteStatus}
                onChange={deleteHandleChange}
              />
              <Typography className="Deleted__Toggle">Suspended</Typography>
            </FormControl>
          </ThemeProvider>
        </Grid>
        <Grid md={2} item justifyContent={CENTER} display={FLEX}>
          <Button
            variant="contained"
            className="submit__button"
            onClick={clearHandler}
          >
            Clear All
          </Button>
        </Grid>
        <Grid md={2} item justifyContent={FLEX_END} display={FLEX}>
          <Button
            variant="contained"
            className="submit__button"
            onClick={downloadHandler}
          >
            Download CSV
          </Button>
        </Grid>
      </Grid>
      <DataTable
        from={RESIDENT_LIST}
        headers={[
          { label: "Name" },
          { label: "Apartment No" },
          { label: "Building Name" },
          { label: "Phone Number" },
          { label: "Status" },
          { label: "Actions" },
        ]}
        data={residents.map((row) => {
          const formattedPhone = row?.phone
            ? `${row.countryCode}-${row.phone.slice(0, 5)}-${row.phone.slice(
                5
              )}`
            : "";
          return {
            id: row?.id,
            commonColumns: [
              fetcResidentName(row),
              row.apartment?.apartmentNumber || "-",
              row.building?.name || "-",
              formattedPhone || "-",
            ],
            residentStatus: row?.residentStatus?.name || "-",
            data: row,
          };
        })}
        onView={onView}
        onEdit={onEdit}
        onDelete={onDelete}
        sortOrderHandler={sortOrderHandler}
        totalRecords={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        sortBy={sortBy}
        sortColumn={sortColumn}
      />
      <ConfirmationModal
        message={confirmationMessage}
        onOkHandler={deleteHandler}
        onCancelHandler={() => {}}
        cancelText="No"
        okText="Yes"
      />
      <DeleteReasonModal
        from={RESIDENT_LIST}
        onOkHandler={deleteHandler}
        onCancelHandler={() => {}}
        cancelText="Cancel"
        okText="Submit"
        isUserRequestOptionAvialable={isUserRequestOptionAvialable}
      />
      <Loader />
    </Paper>
  );
};

export default List;
