import { Box, Button, Typography, IconButton, Grid, Tooltip, Paper, ListItemText } from "@mui/material";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import './UploadPhoneNumbers.scss'
import DeleteIcon from "@mui/icons-material/Delete";
import {
    AUTO,
    BLACK,
    BOLD,
    CENTER,
    DEEP_OCEAN_BLUE,
    FLEX,
    IMAGE_PICKER_BORDER_COLOR,
    NONE,
    SOLID,
} from "../../utils/constants";
import { useEffect, useState } from "react";
import { UploadPreboardedPhoneNumbers } from "../../services/prevalidate-phone-numbers";
import { enqueueSnackbar } from "notistack";

const UploadPhoneNumbers = (props) => {
    
    const { defaultImage } = props;
    const [fileName, setFileName] = useState(
        defaultImage ? defaultImage.name : null
    );
    const [fileURL, setFileURL] = useState(null);

    useEffect(() => {
        return () => {
            if (fileURL) {
                URL.revokeObjectURL(fileURL);
            }
        };
    }, [fileURL]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // const url = URL.createObjectURL(file);
            setFileName(file.name);
            setFileURL(file);
        }
    };

    const handleDelete = () => {
        setFileName(null);
        if (fileURL) {
            URL.revokeObjectURL(fileURL);
        }
        setFileURL(null);
    };

    const submitHandler = async () => {
        const result = await UploadPreboardedPhoneNumbers(fileURL);
        if (result.success) {
            setFileURL(null);
            setFileName(null);
            enqueueSnackbar(result?.data?.message, {
                variant: "success",
            });
        } else {
            enqueueSnackbar(result?.data, { variant: "error" });
        }
    };

    return (
        <Paper
            className="paper__container"
            sx={{ height: AUTO }}
            elevation={4}
        >
            <Typography key="3" color={BLACK} marginBottom={2}>
                <ListItemText
                    primary={
                        <Typography
                            sx={{ color: DEEP_OCEAN_BLUE, fontWeight: BOLD }}
                        >
                            Generate Welcome Letter
                        </Typography>
                    }
                />
            </Typography>
            {!fileName ? (
                <Box
                    sx={{
                        "&:hover": { border: `1px ${SOLID} ${DEEP_OCEAN_BLUE}` },
                        border: `1px ${SOLID} ${IMAGE_PICKER_BORDER_COLOR}`,
                        position: "relative",
                    }}
                    height="100% !important"
                    className="upload_phone_numbers__picker__container"
                >
                    <>
                        <input
                            accept=".csv"
                            id={`csv-upload-phone`}
                            className="file_upload_input"
                            type="file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor={`csv-upload-phone`}>
                            <Button
                                component="span"
                                className="upload_phone_numbers__picker__button"
                            >
                                <div className={"upload__input"}>
                                    <FileUploadOutlinedIcon className="upload_phone_numbers_icon" />
                                    <Typography fontSize="1rem" textTransform={NONE}>
                                        Upload Sheet
                                    </Typography>
                                </div>
                            </Button>
                        </label>
                    </>
                </Box>
            ) : (
                <Box className="Upload__pdf__filename">
                    <Tooltip title={fileName} arrow>
                        <Typography
                            variant="body1"
                            sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flexGrow: 1 }}
                        >
                            {fileName}
                        </Typography>
                    </Tooltip>
                    <Grid display={FLEX}>
                        <IconButton onClick={handleDelete} size="small">
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Box>
            )}
            <Grid item md={6} marginTop={6} display={FLEX} justifyContent={CENTER}>
                <Button
                    type="button"
                    variant="contained"
                    className="submit__button"
                    onClick={submitHandler}
                >
                    Submit
                </Button>
            </Grid>
        </Paper>
    );
};

export default UploadPhoneNumbers;
