import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../redux/Slices/CommonSlice";
import { enqueueSnackbar } from "notistack";
import loader from "../../assets/images/Loader.gif";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fetchServiceProviderByToken,
  acceptTermsAndConditions,
} from "../../services/service-provider";
import {
  Container,
  Grid,
  Paper,
  Button,
  Typography,
  Box,
  Card,
  FormControl,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert,
} from "@mui/material";
import "./ServiceProviderTermsAndConditions.scss";
import NivasiDarkIcon from "../../assets/images/nivasi-icon-dark.png";
import PrivacyAndTermsIcon from "../../utils/images/privacy-and-terms.png";
import FooterImage from "../../utils/images/footer.png";
import { CENTER, DEEP_OCEAN_BLUE, FLEX, SMALL } from "../../utils/constants";
import axios from "axios";

const ServiceProviderTermsAndConditions = (props) => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const [serviceProvider, setServiceProvider] = useState(null);
  const [serviceProviderName, setServiceProviderName] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [status, setStatus] = useState(null);
  const [checked, setChecked] = useState(false);
  const [spinning, setSpinning] = useState(true);
  const [ip, setIp] = useState("");
  const [url, setUrl] = useState("");
  const [deviceInfo, setDeviceInfo] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchIpDetails = async () => {
      try {
        await axios
          .get("https://api.ipify.org?format=json")
          .then((response) => {
            setIp(response.data.ip);
          });
        const locationResponse = await axios.get(
          `https://ipwho.is/${ip}`
        );
        const { latitude, longitude } = locationResponse.data;
        const url = `https://maps.google.com/?q=${latitude},${longitude}`;
        setUrl(url);
      } catch (error) {
        console.error("Error fetching IP details:", error);
      }
    };
    fetchIpDetails();
    setDeviceInfo(navigator.userAgent);
  }, [ip]);

  const handleOpen = async () => {
    setLoading(true);
    try {
      const response = await acceptTermsAndConditions(token, ip, url, deviceInfo);
      if (response.success && response.data) {
        setTermsAccepted(true);
      } else {
        setAlertContent(
          "We're experiencing an issue. Kindly wait while we resolve it."
        );
        setAlertOpen(true);
      }
    } catch (error) {
      console.error("Error accepting terms:", error);
      setAlertContent("An unexpected error occurred. Please try again.");
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    setAlertContent("");
  };

  useEffect(() => {
    (async () => {
      dispatch(startLoading());
      const response = await fetchServiceProviderByToken(token);
      if (response.success && response.data) {
        const serviceProviderFromResponse = response?.data?.serviceProvider;
        setServiceProvider(serviceProviderFromResponse);
        setStatus(serviceProviderFromResponse.status);
        setServiceProviderName(serviceProviderFromResponse.name);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
      setSpinning(false);
    })();
  }, [dispatch, token, termsAccepted]);

  const footerComponent = () => {
    return <Paper>
      <Box className="footer">
        <Grid container md={10} spacing={1}>
          <Grid item md={6} xs={12}>
            <Typography
              className="community-thrives"
              paddingTop={"20px"}
            >
              Building Connected Communities.
            </Typography>
            <Typography className="rights-reserved" marginBottom={"20px"}>
              © <span id="year">{new Date().getFullYear()}</span> All
              Rights Reserved, Ekam Illam Private Limited
            </Typography>
          </Grid>
          <Grid item md={6} xs={12} paddingTop={"20px"}>
            <Typography className="support-section support-contents">
              Have questions? <br /> You can email our support team at
              <br />
              <a href="mailto:support@nivasiapp.com" className="support-content">
                support@nivasiapp.com
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <img
        src={FooterImage}
        alt="footer"
        style={{ width: "100%", backgroundSize: "contain" }}
      />
    </Paper>;
  };

  const acceptedOrExpiredComponent = (title, content1, content2) => {
    return <Paper sx={{ overflow: "auto" }} style={{ overflow: "auto" }}>
      <Paper className="container service__container" elevation={4}>
        <Grid container spacing={2} padding={2}>
          <Grid container spacing={1} display={"flex"} md={12} xs={12}>
            <div>
              <img src={NivasiDarkIcon} className="navbar-brand nivasi-icon" alt="Nivasi Icon" />
            </div>
          </Grid>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={9} md={8} alignContent={"center"} alignItems={"center"} >
                <Typography variant="h4" className="privacy-terms-heading">
                  {title}
                </Typography>
                <Typography className="thankyou-text">
                  {content2 ? (
                    <>
                      {content1}
                      <br /><br />
                      {content2}
                    </>
                  ) : (content1)}
                </Typography>
              </Grid>
              <Grid item xs={3} md={4}>
                <img src={PrivacyAndTermsIcon} alt="" style={{ width: "100%" }} />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Paper>
      {footerComponent()}
    </Paper>;
  };

  const acceptedComponent = () => {
    const title = (serviceProviderName || "Dear Service Provider") + ",";
    const content1 = "Thank you for accepting and acknowledging our terms and conditions. Your profile is now live on Nivasi app. Residents interested in your services will reach out to you at the phone number you provided.";
    const content2 = "To enhance your profile and make it appealing to more residents, please request residents that availed your service, to provide reviews of your services on the app.";
    return acceptedOrExpiredComponent(title, content1, content2);
  };

  const expiredComponent = () => {
    return acceptedOrExpiredComponent(
      "Link Expired or Invalid",
      "We apologize, but the link you accessed is no longer valid or may have expired. If you are trying to accept terms and conditions, and running into this error, please reach out to our support team for further assistance."
    );
  };

  const termsAndConditionsComponent = () => {
    return <Paper sx={{ overflow: "auto", height: "100vh" }} style={{ overflow: "auto" }}>
      <Paper className="container service__container" elevation={4}>
        <Grid>
          <Grid container spacing={1} display={"flex"} md={12} xs={12}>
            <div>
              <img src={NivasiDarkIcon} className="navbar-brand nivasi-icon" alt="Nivasi Icon" />
            </div>
          </Grid>
          <Grid>
            <Grid container spacing={1} display={"flex"} md={12} xs={12}>
              <Grid item xs={12} md={12}>
                <Typography variant="h4" className={"privacy-terms-heading"}>
                  Preview your Nivasi Profile
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <img className="profile-image" src={serviceProvider.image} alt="profile" />
              </Grid>
            </Grid>
          </Grid>
          <Grid md={6} xs={12}>
            <Grid marginTop={5} lineHeight={5} md={6} xs={12}>
              <Grid paddingBottom={1} md={6} xs={12}>
                <Grid item md={3} xs={6}>
                  <Typography fontWeight={"600"}>Contact Name:</Typography>
                </Grid>
                <Grid item md={3} xs={6}>
                  <Typography>{serviceProvider?.name}</Typography>
                </Grid>
              </Grid>
              {serviceProvider?.businessName && (
                <Grid paddingBottom={1}>
                  <Grid>
                    <Typography fontWeight={"600"}>
                      Business Name:
                    </Typography>
                  </Grid>
                  <Grid item md={9}>
                    <Typography>{serviceProvider?.businessName}</Typography>
                  </Grid>
                </Grid>
              )}
              <Grid paddingBottom={1}>
                <Grid item md={3}>
                  <Typography fontWeight={"600"}>Phone:</Typography>
                </Grid>
                <Grid item md={9}>
                  <Typography>{serviceProvider?.phoneNumber}</Typography>
                </Grid>
              </Grid>
              {serviceProvider?.email && (
                <Grid paddingBottom={1}>
                  <Grid item md={3}>
                    <Typography fontWeight={"600"}>Email:</Typography>
                  </Grid>
                  <Grid item md={9}>
                    <Typography>{serviceProvider?.email}</Typography>
                  </Grid>
                </Grid>
              )}
              <Grid paddingBottom={1}>
                <Grid item md={3}>
                  <Typography fontWeight={"600"}>Location:</Typography>
                </Grid>
                <Grid item md={9}>
                  <Typography>{serviceProvider?.address}</Typography>
                </Grid>
              </Grid>
              <Grid paddingBottom={1}>
                <Grid>
                  <Typography fontWeight={"600"}>
                    Google Maps Link:{" "}
                  </Typography>
                </Grid>

                <Grid item md={9}>
                  <Typography sx={{ wordBreak: "break-word" }}>
                    <a href={serviceProvider?.locationUrl}>
                      {serviceProvider?.locationUrl}
                    </a>
                  </Typography>
                </Grid>
              </Grid>
              <Grid paddingBottom={1}>
                <Grid item md={3}>
                  <Typography fontWeight={"600"}>Title:</Typography>
                </Grid>
                <Grid item md={9}>
                  <Typography>{serviceProvider?.title}</Typography>
                </Grid>
              </Grid>
              <Grid>
                <Grid item md={3}>
                  <Typography fontWeight={"600"}>About:</Typography>
                </Grid>
                <Grid item md={9}>
                  <Typography sx={{ whiteSpace: "pre-line" }}>
                    {serviceProvider?.aboutTheBusiness}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Box mt={5} mb={2} alignItems={CENTER} display={FLEX}>
              <FormControl>
                <FormControlLabel
                  key={"terms"}
                  control={
                    <Checkbox
                      sx={{
                        color: DEEP_OCEAN_BLUE,
                      }}
                      size={SMALL}
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                  }
                />
              </FormControl>
              <Typography className="label__fontsize">
                I agree to the{" "}
                <a href="https://www.nivasiapp.com/service-provider/terms-of-service">
                  Terms of Service
                </a>{" "}
                of Nivasi.
              </Typography>
            </Box>
            <Box
              alignItems={"center"}
              alignSelf={"center"}
              alignContent={"center"}
              paddingTop={"25px"}
              textAlign={"center"}
            >
              <Button
                className="accept-button"
                variant="contained"
                onClick={handleOpen}
                disabled={!checked || loading}
                style={{
                  background: loading ? "transparent" : checked ? "black" : "lightgray",
                  color: loading ? "black" : "white",
                }}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : "I accept"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      {footerComponent()}
    </Paper>;
  };

  const renderComponent = () => {
    if (status === "Active") {
      return acceptedComponent();
    } else if (status !== "Pending") {
      return expiredComponent();
    } else {
      return termsAndConditionsComponent();
    }
  };

  return (
    <React.Fragment>
      {!spinning ? (renderComponent()) : (
        <Card
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 1000,
          }}
        >
          <img src={loader} alt="" className="Nivasi__Loader" />
        </Card>
      )}
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {alertContent}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default ServiceProviderTermsAndConditions;
