import {
  Autocomplete,
  Grid,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState } from "react";
import ModalView from "./ModalView";
import {
  DEEP_OCEAN_BLUE,
  FLEX,
  SMALL,
  CENTER,
  FLEX_START,
  COLUMN,
  BOLD,
  SPACE_BETWEEN,
} from "../../utils/constants";
import { NumberInputStyles } from "../Common/CommonStyles";

const PhoneAutocomplete = (props) => {
  const {
    selectedPhoneData,
    handlePhoneNumberChange,
    setSelectedPhoneData,
    phoneData,
    values,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    countryCodes,
    onCountryCodeChanged,
    selectedCountryCode,
  } = props;

  const [viewModal, setViewModal] = useState(false);
  const [serviceProvider, setServiceProvider] = useState(null);

  const viewModalHandler = (e, option) => {
    e.stopPropagation();
    setServiceProvider(option);
    setViewModal(true);
  };

  return (
    <>
      <Autocomplete
        freeSolo
        inputValue={values?.phone}
        value={selectedPhoneData}
        onInputChange={(_, newValue) => {
          if (newValue.length <= 10) {
            setFieldValue("phone", newValue);
            handlePhoneNumberChange(newValue);
          }
        }}
        onChange={(_, newValue) => setSelectedPhoneData(newValue)}
        options={phoneData}
        getOptionLabel={(option) => option?.phone}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid
                container
                alignItems={CENTER}
                display={FLEX}
                justifyContent={SPACE_BETWEEN}
              >
                <Grid item width={60} height={60}>
                  <img src={option?.image} alt="" width="100%" height="100%" />
                </Grid>
                <Grid item width={"70%"}>
                  <Grid
                    container
                    alignItems={FLEX_START}
                    display={FLEX}
                    flexDirection={COLUMN}
                  >
                    <Grid item width={"100%"}>
                      <Typography
                        variant="body2"
                        color={DEEP_OCEAN_BLUE}
                        noWrap
                        textOverflow={"ellipsis"}
                        fontWeight={BOLD}
                      >
                        {`${option?.firstName} ${option?.lastName}`}
                      </Typography>
                    </Grid>
                    <Grid item width={"100%"}>
                      <Typography variant="body2">
                        {option?.phone || ""}
                      </Typography>
                    </Grid>
                    <Grid item width={"100%"}>
                      <Typography variant="body2">
                        {option?.businessName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <VisibilityIcon
                    fontSize={SMALL}
                    sx={{ color: DEEP_OCEAN_BLUE }}
                    onClick={(e) => {
                      viewModalHandler(e, option);
                    }}
                  />
                </Grid>
              </Grid>
            </li>
          );
        }}
        renderInput={(params) => (
          <>
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                style={{ height: "40px" }}
                className="phone-code"
              >
                <TextField
                  select
                  label="Country Code"
                  className="custom-label"
                  value={selectedCountryCode}
                  disabled={true}
                  defaultValue={`IN (+91)`}
                  onChange={onCountryCodeChanged}
                  sx={NumberInputStyles}
                  size={SMALL}
                  fullWidth
                >
                  {countryCodes.map((option) => (
                    <MenuItem key={option.code} value={`${option.phone}`}>
                      {`${option.code} (${option.phone})`}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...params}
                  required
                  className="custom-label"
                  label="Phone"
                  fullWidth
                  type="tel"
                  sx={NumberInputStyles}
                  size={SMALL}
                  name="phone"
                  onBlur={handleBlur}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                  onKeyDown={(e) => {
                    const allowedKeys = [
                      "Backspace",
                      "Tab",
                      "ArrowLeft",
                      "ArrowRight",
                      "Delete",
                      "Control",
                      "v", 
                      "x", 
                      "c"
                    ];
                    if (!/[0-9]/.test(e.key) && !allowedKeys.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                ></TextField>
              </Grid>
            </Grid>
          </>
        )}
      />
      <ModalView
        viewModal={viewModal}
        setViewModal={setViewModal}
        serviceProvider={serviceProvider}
      />
    </>
  );
};

export default PhoneAutocomplete;
