
import React from "react";
import Layout from "../../components/Layout/Layout";
import UploadPhoneNumbers from "../../components/PreboardPhoneNumbers/UploadPhoneNumbers";

const Preboard = () => {
    return (
        <Layout>
          <UploadPhoneNumbers/>
        </Layout>
      );
};

export default Preboard;