import React, { useEffect, useState } from "react";
import { Document, Page, Text, View, Image, Link } from "@react-pdf/renderer";
import styles from "./PdfStyles";
import termsQr from "../../assets/images/Terms-QR.png";
import logo from "../../assets/images/nivasi-icon-dark.png";
import nivasiLogo from "../../assets/images/privacy-and-terms.png";
import footerImage from "../../assets/images/footer.png";
import footerLogoImage from "../../assets/images/nivasi-icon-light.png";
import copyright from "../../assets/images/copyright.png";

const TermsConditionsPdf = ({ serviceProvider, language }) => {
  const [formattedDate, setFormattedDate] = useState("");
  useEffect(() => {
    const today = new Date();
    const options = { day: "numeric", month: "long", year: "numeric" };
    console.log("today", today.toLocaleDateString("en-GB", options));
    setFormattedDate(today.toLocaleDateString("en-GB", options));
  }, []);

  return language?.language === "English" ? (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.columns}>
            <View style={styles.column}>
              <Image style={styles.logo} src={logo} />
            </View>
            <View style={styles.column}>
              <Image style={styles.nivasiLogo} src={nivasiLogo} />
            </View>
          </View>
          <View style={styles.bodyContent}>
            <View style={styles.row}>
              <Text style={styles.label}>Contact Name</Text>
              <Text style={styles.value}>
                :{" "}
                {serviceProvider?.serviceProvider?.namePrefix +
                  " " +
                  serviceProvider?.serviceProvider?.firstName +
                  " " +
                  serviceProvider?.serviceProvider?.lastName}
              </Text>
            </View>
            {serviceProvider?.serviceProvider?.businessName && (
              <View style={styles.row}>
                <Text style={styles.label}>Business Name</Text>
                <Text style={styles.value}>
                  : {serviceProvider?.serviceProvider?.businessName}
                </Text>
              </View>
            )}
            <View style={styles.row}>
              <Text style={styles.label}>Phone Number</Text>
              <Text style={styles.value}>
                : {serviceProvider?.serviceProvider?.phone}
              </Text>
            </View>
            {serviceProvider?.serviceProvider?.email && (
              <View style={styles.row}>
                <Text style={styles.label}>Email Address</Text>
                <Text style={styles.value}>
                  : {serviceProvider?.serviceProvider?.email}
                </Text>
              </View>
            )}
            <View style={styles.row}>
              <Text style={styles.label}>Location</Text>
              <Text style={styles.value}>
                : {serviceProvider?.serviceProvider?.address?.locality}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Title</Text>
              <Text style={styles.value}>
                : {serviceProvider?.serviceProvider?.title}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>About</Text>
              <Text style={styles.value}>
                : {serviceProvider?.serviceProvider?.description}
              </Text>
            </View>

            <Text style={styles.bodyNumber}></Text>
            <Text style={styles.bodyTitle}>Terms of Service</Text>
            <Image style={styles.logo} src={termsQr} />
            <Link
              src={"http://nivasiapp.com/service-provider/terms-of-service"}
              style={styles.bodyPara}
            >
              http://nivasiapp.com/service-provider/terms-of-service
            </Link>
            <Text style={styles.bodyPara}>
              I’ve read through the terms of service and confirm my acceptance
              by signing this document.
            </Text>
          </View>
          <View style={styles.columns}>
            <View style={styles.column}>
              <Text style={styles.signDate}>Date: {formattedDate}</Text>
              <Text style={styles.signPlace}>Place: </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.sign}>Signature</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  ) : (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.columns}>
            <View style={styles.column}>
              <Image style={styles.logo} src={logo} />
              <Text style={styles.tamilHeading}>
                {"விதிமுறைகள் மற்றும் நிபந்தனைகள் " +
                  serviceProvider?.serviceProvider?.firstName +
                  " " +
                  serviceProvider?.serviceProvider?.lastName}
              </Text>
              <Text style={styles.tamilDate}>
                புதுப்பிக்கப்பட்டது: 14 June 2024
              </Text>
            </View>
            <View style={styles.column}>
              <Image style={styles.nivasiLogo} src={nivasiLogo} />
            </View>
          </View>
          <View style={styles.bodyContent}>
            <View style={styles.row}>
              <Text style={styles.label}>Business Name</Text>
              <Text style={styles.value}>
                : {serviceProvider?.serviceProvider?.businessName}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Phone Number</Text>
              <Text style={styles.value}>
                : {serviceProvider?.serviceProvider?.phone}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Delivery Type</Text>
              <Text style={styles.value}>
                :{" "}
                {serviceProvider?.serviceProvider?.homeVisit
                  ? "Home Visit"
                  : "Door Delivery"}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>About the Business</Text>
              <Text style={styles.value}>
                : {serviceProvider?.serviceProvider?.description}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Location</Text>
              <Text style={styles.value}>
                : {serviceProvider?.serviceProvider?.address?.locality}
              </Text>
            </View>
            <Text style={styles.bodyNumber}>01</Text>
            <Text style={styles.tamilBodyTitle}>அறிமுகம்</Text>
            <Text style={styles.tamilBodyPara}>
              நிவாசிக்கு வரவேற்கிறோம். இந்த விதிமுறைகள் மற்றும் நிபந்தனைகள்
              நிவாசி பயன்பாட்டில் பட்டியலிடப்படுவதற்கு நீங்கள் கடைப்பிடிக்க
              ஒப்புக்கொள்ளும் விதிகள் மற்றும் விதிமுறைகளை {"\n"}கோடிட்டுக்
              காட்டுகின்றன.{"\n"}
            </Text>
            <Text style={styles.bodyNumber}>02</Text>
            <Text style={styles.tamilBodyTitle}>வரையறைகள்</Text>
            <Text style={styles.tamilBodyPara}>
              சேவை வழங்குநர்:{" "}
              {serviceProvider?.serviceProvider?.firstName +
                " " +
                serviceProvider?.serviceProvider?.lastName}
              , சமூகத்திற்கு சேவைகளை வழங்கும் நிறுவனம்.{"\n"}
              பயனர்: சேவை வழங்குநரால் வழங்கப்படும் சேவைகளைப் பயன்படுத்தும்
              எந்தவொரு தனிநபர் அல்லது சமூக குடியிருப்பாளர்.{"\n"}
              சேவைகள்: சமூகத்திற்கு சேவை வழங்குநரால் வழங்கப்படும் அனைத்து
              சேவைகள் மற்றும் தயாரிப்புகள்.{"\n"}
            </Text>
            <Text style={styles.bodyNumber}>03</Text>
            <Text style={styles.tamilBodyTitle}>வழங்கப்படும் சேவைகள்</Text>
            <Text style={styles.tamilBodyPara}>
              சமூகத்திற்கு பின்வரும் சேவைகளை வழங்க ஒப்புக்கொள்கிறீர்கள்:{"\n"}
              {serviceProvider.serviceProvider.categories.map((category) => (
                <Text>
                  * {category.name}
                  {"\n"}
                </Text>
              ))}
            </Text>
            <View wrap={false}>
              <Text style={styles.bodyNumber}>04</Text>
              <Text style={styles.tamilBodyTitle}>
                சேவை வழங்குநரின் பொறுப்புகள்{"\n"}
              </Text>
              <Text style={styles.tamilBodyPara}>
                * சமூகத்திற்கு அவர்கள் வழங்கும் சேவைகள் பற்றிய துல்லியமான
                மற்றும் முழுமையான தகவலை {"\u00A0\u00A0"} நீங்கள் வழங்க வேண்டும்.
                {"\n"}* நீங்கள் சேவைகளை வழங்கும் குடியிருப்பாளர்களின் தொடர்புத்
                தகவலை நீங்கள் அணுகலாம். {"\n"}
                {"\u00A0\u00A0\u00A0"}
                இந்தத் தகவலின் இரகசியத்தன்மையைப் பேணுவதற்கு நீங்கள்
                பொறுப்பாவீர்கள்.
                {"\n"}* சமூகத்திற்குள் சேவைகளை வழங்கும்போது நீங்கள்
                பொருந்தக்கூடிய அனைத்து சட்டங்கள் {"\n"}
                {"\u00A0\u00A0"} மற்றும் ஒழுங்குமுறைகளுக்கு இணங்க வேண்டும்.
                {"\n"}* நீங்கள் மோசடி அல்லது சட்டவிரோத நடவடிக்கைகளில் ஈடுபட
                மாட்டீர்கள்.{"\n"}
              </Text>
            </View>
            <View wrap={false}>
              <Text style={styles.bodyNumber}>05</Text>
              <Text style={styles.tamilBodyTitle}>
                குடியிருப்பாளர் மற்றும் சேவை வழங்குநர்
              </Text>
              <Text style={styles.tamilBodyPara}>
                நீங்கள் சேவை வழங்கும் சமூகத்தில் வசிப்பவராக இருந்தால், நீங்கள்
                வழங்கும் சேவைகளை ஆப்ஸில் காட்சிப்படுத்தலாம். எவ்வாறாயினும், இந்த
                சேவைகள் எந்தவொரு சங்க விதிகளையும் மீறுவதாக இருக்கக்கூடாது. அவை
                மீறப்பட்டதாகக் கண்டறியப்பட்டால், அவற்றை பயன்பாட்டிலிருந்து அகற்ற
                நிறுவனத்திற்கு உரிமை உண்டு. சில சூழ்நிலைகளில், குடியுரிமைச்
                சேவைத் தகவலை பயன்பாட்டில் பட்டியலிட அனுமதிக்கும் முன்,
                நிறுவனத்திற்கு குடியுரிமைச் சங்கத்திடமிருந்து எழுத்துப்பூர்வ
                ஒப்புதல் தேவைப்படலாம்.{"\n"}
              </Text>
            </View>
            <View wrap={false}>
              <Text style={styles.bodyNumber}>06</Text>
              <Text style={styles.tamilBodyTitle}>கணக்கு நீக்கம்</Text>
              <Text style={styles.tamilBodyPara}>
                admin@nivasiapp.com க்கு மின்னஞ்சலை எழுதுவதன் மூலம் நிவாசியுடன்
                உங்கள் சேவை வழங்குநர் பட்டியலை நீக்க உங்களுக்கு உரிமை உள்ளது.
                உங்கள் பட்டியல் செயலிழக்கப்படும் மற்றும் குடியிருப்பாளர்களால்
                பார்க்க முடியாது. உங்கள் கணக்கை மீட்டெடுக்கும்படி நீங்கள்
                கோரும்போது,{"\n"} ​​அது நிறுவனத்தின் முழு விருப்பத்தின் பேரில்
                செய்யப்படும்.{"\n"}
              </Text>
            </View>
            <View wrap={false}>
              <Text style={styles.bodyNumber}>07</Text>
              <Text style={styles.tamilBodyTitle}>கணக்கு நிறுத்தம்</Text>
              <Text style={styles.tamilBodyPara}>
                சமூகத்தில் செயல்படாத, போதிய ஈடுபாடு இல்லாத அல்லது விதிமுறைகள்
                மற்றும் நிபந்தனைகளை மீறும் சேவை வழங்குநர்களை அகற்றுவதற்கான உரிமை
                எங்களுக்கு உள்ளது.{"\n"}
              </Text>
            </View>
            <View wrap={false}>
              <Text style={styles.bodyNumber}>08</Text>
              <Text style={styles.tamilBodyTitle}>கிடைக்கும் சேவை</Text>
              <Text style={styles.tamilBodyPara}>
                வழக்கமான வணிக நேரங்கள் அல்லது உங்கள் பட்டியலில்
                குறிப்பிடப்பட்டுள்ள மணிநேரங்களில் சேவைகள் கிடைப்பதை உறுதிசெய்ய
                நீங்கள் நியாயமான முயற்சிகளை மேற்கொள்வீர்கள்.{"\n"}
              </Text>
            </View>
            <View wrap={false}>
              <Text style={styles.bodyNumber}>09</Text>
              <Text style={styles.tamilBodyTitle}>பொறுப்பு வரம்பு{"\n"}</Text>
              <Text style={styles.tamilBodyPara}>
                பயன்பாட்டில் உங்கள் சேவைகளை பட்டியலிடுவதால் அல்லது அது தொடர்பாக
                ஏற்படும் நேரடி, மறைமுக, தற்செயலான, சிறப்பு அல்லது அதன் விளைவாக
                ஏற்படும் சேதங்களுக்கு நிறுவனம் பொறுப்பேற்காது. சேவைகளில் இருந்து
                எழும் அல்லது தொடர்புடைய எந்தவொரு கோரிக்கைகளுக்கும் நிறுவனத்தின்
                {"\n"}மொத்தப் பொறுப்பு, உரிமைகோரலுக்கு முந்தைய ஆறு மாதங்களில்
                நிறுவனத்திற்கு நீங்கள் செலுத்திய தொகையை விட அதிகமாக
                இருக்கக்கூடாது.{"\n"}
              </Text>
            </View>
            <View wrap={false}>
              <Text style={styles.bodyNumber}>10</Text>
              <Text style={styles.tamilBodyTitle}>இழப்பீடு</Text>
              <Text style={styles.tamilBodyPara}>
                நிவாசியில் உங்கள் பட்டியலிலிருந்து எழும் அல்லது தொடர்புடைய
                எந்தவொரு உரிமைகோரல்கள், {"\n"}பொறுப்புகள், சேதங்கள் மற்றும்
                செலவுகள் (நியாயமான வழக்கறிஞர் கட்டணம் உட்பட) ஆகியவற்றிலிருந்து
                நிறுவனம், அதன் துணை நிறுவனங்கள் மற்றும் அந்தந்த அதிகாரிகள்,
                இயக்குநர்கள், ஊழியர்கள் மற்றும் முகவர்களுக்கு இழப்பீடு
                வழங்கவும், பாதிப்பில்லாதவர்களாகவும் இருக்க ஒப்புக்கொள்கிறீர்கள்.
                {"\n"}
              </Text>
            </View>
            <View wrap={false}>
              <Text style={styles.bodyNumber}>11</Text>
              <Text style={styles.tamilBodyTitle}>தனியுரிமை</Text>
              <Text style={styles.tamilBodyPara}>
                சமூக குடியிருப்பாளர்களின் தொடர்புத் தகவலை தவறாகப் பயன்படுத்த
                வேண்டாம் என்று ஒப்புக்கொள்கிறீர்கள்.{"\n"}
              </Text>
            </View>
            <View wrap={false}>
              <Text style={styles.bodyNumber}>12</Text>
              <Text style={styles.tamilBodyTitle}>ஆளும் சட்டம்</Text>
              <Text style={styles.tamilBodyPara}>
                இந்த விதிமுறைகள் இந்திய சட்டங்களின்படி நிர்வகிக்கப்படும் மற்றும்
                கட்டமைக்கப்படும். இந்த விதிமுறைகளிலிருந்து அல்லது அது தொடர்பாக
                எழும் எந்தவொரு சர்ச்சைகளும் {"\n"}இந்திய நீதிமன்றங்களின்
                பிரத்யேக அதிகார வரம்பிற்கு உட்பட்டது.{"\n"}
              </Text>
            </View>
            <View wrap={false}>
              <Text style={styles.bodyNumber}>13</Text>
              <Text style={styles.tamilBodyTitle}>திருத்தங்கள்</Text>
              <Text style={styles.tamilBodyPara}>
                இந்த விதிமுறைகளை எந்த நேரத்திலும் திருத்தும் உரிமை நிவாசிக்கு
                உள்ளது. எங்கள் இணையதளத்தில் புதுப்பிக்கப்பட்ட விதிமுறைகளை
                இடுகையிடுவதன் மூலம் ஏதேனும் மாற்றங்கள் இருந்தால் உங்களுக்குத்
                தெரிவிக்கப்படும். ஏதேனும் மாற்றங்களுக்குப் பிறகு சேவைகளைத்
                தொடர்ந்து பயன்படுத்துவது புதிய விதிமுறைகளை ஏற்றுக்கொள்வதைக்
                குறிக்கிறது.{"\n"}
              </Text>
            </View>
            <View wrap={false}>
              <Text style={styles.bodyNumber}>14</Text>
              <Text style={styles.tamilBodyTitle}>தொடர்பு தகவல்{"\n"}</Text>
              <Text style={styles.tamilBodyPara}>
                இந்த விதிமுறைகள் குறித்து உங்களுக்கு ஏதேனும் கேள்விகள் அல்லது
                கவலைகள் இருந்தால், தயவுசெய்து எங்களை தொடர்பு கொள்ளவும்: {"\n"}
                Ekam Illam Private Limited{"\n"}46 Fanepet 1st Street, Subbu
                Towers 3rd Floor, Nandanam, Chennai - 600 035
                {"\n"}
                info@nivasiapp.com{"\n"} +91 90920-90569
              </Text>
            </View>
          </View>
          <View style={styles.columns}>
            <View style={styles.column}>
              <Text style={styles.signDate}>Date: {formattedDate}</Text>
              <Text style={styles.signPlace}>Place: </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.sign}>Signature</Text>
            </View>
          </View>
        </View>
        <View style={styles.termsFooter}>
          <View style={styles.columns}>
            <View style={styles.column}>
              <Text style={styles.termsFooterCommunity}>
                Every community can thrive.
              </Text>
              <Image style={styles.footerLogoImage} src={footerLogoImage} />
              <Text style={styles.registeredText}>
                <Image src={copyright} />
                {"  "}2024 All Rights Reserved, Ekam Illam
              </Text>
            </View>
            <View style={styles.column}>
              <Text style={styles.termsFooterSupport}>
                Support{"\n"}
                <Text style={styles.termsFooterSupportText}>
                  Have questions? You can{" "}
                  <Text style={styles.underline}>get in touch</Text>
                  {"\n"}You can also text our support team at{"\n"}
                  <Text style={styles.underline}>+91 8882134232</Text>
                  {"\n"}
                  email us at{" "}
                  <Text style={styles.underline}>support@nivasiapp.com</Text>
                </Text>
              </Text>
            </View>
          </View>
        </View>
        <Image style={styles.footerImage} src={footerImage} />
      </Page>
    </Document>
  );
};

export default TermsConditionsPdf;
