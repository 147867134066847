import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
  Checkbox,
  Autocomplete,
  TextField,
  Box,
  Chip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "../Common/DataTable";
import ConfirmationModal from "../Common/ConfirmationModal";
import Loader from "../Common/Loader";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  deleteServiceProvider,
  fetchAllServiceProvider,
} from "../../services/service-provider";
import { enqueueSnackbar } from "notistack";
import {
  openConfirmationModal,
  openDeleteReasonModal,
  startLoading,
  stopLoading,
} from "../../redux/Slices/CommonSlice";
import {
  ALL,
  RESIDENT_PROVIDER_FILTER,
  DEEP_OCEAN_BLUE,
  REINSTATE_MESSAGE,
  SERVICE_PROVIDER_LIST,
  SMALL,
  START,
  SERVICE_PROVIDER_STATUS_FILTER,
  FLEX,
  CENTER,
  SPACE_BETWEEN,
} from "../../utils/constants";
import { fetchAllServiceCategory } from "../../services/service-category";
import { fetchAllPreferredLanguage } from "../../services/preferred-language";
import DeleteReasonModal from "../Common/DeleteReasonModal";
import FlagModal from "./FlagModal";
import { handleInputChange } from "../../helpers/GetMultiselectFilterValues";
import { serviceProviderCsvReport } from "../../services/csvReport";

const List = () => {
  const initialFilterValues = localStorage.getItem(
    "serviceProviderFilterValues"
  )
    ? JSON.parse(localStorage.getItem("serviceProviderFilterValues"))
    : {
      category: [ALL],
      status: [ALL],
      preferredLanguage: [ALL],
      resident: [ALL],
      flagged: false,
    };

  const [serviceProviders, setServiceProviders] = useState([]);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const [openFlagPopover, setOpenFlagPopover] = useState(null);
  const [flagMessage, setFlagMessage] = useState({});
  const [serviceCategories, setServiceCategories] = useState([]);
  const [preferredLanguage, setPreferredLanguage] = useState([]);
  const filterValuesRef = useRef(filterValues);
  const searchValuesRef = useRef(searchValue);
  const rowsPerPageRef = useRef(rowsPerPage);
  const [sortBy, setSortBy] = useState("asc");
  const [sortColumn, setSortColumn] = useState("Name");
  const dispatch = useDispatch();
  const [deleteServiceProviderId, setDeleteServiceProviderId] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const isInitialRender = useRef(true);

  useEffect(() => {
    setPage(0);
    localStorage.setItem(
      "serviceProviderFilterValues",
      JSON.stringify({
        category: filterValues.category,
        status: filterValues.status,
        preferredLanguage: filterValues.preferredLanguage,
        resident: filterValues.resident,
        flagged: filterValues.flagged,
      })
    );
  }, [filterValues]);

  useEffect(() => {
    setPage(0);
    filterValuesRef.current = filterValues;
  }, [filterValues]);

  useEffect(() => {
    searchValuesRef.current = searchValue;
  }, [searchValue]);

  useEffect(() => {
    rowsPerPageRef.current = rowsPerPage;
  }, [rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData(newPage, sortColumn, sortBy);
  };

  const sortOrderHandler = (header) => {
    setSortBy(sortBy === "asc" ? "desc" : "asc");
    setSortColumn(header);
  };

  const fetchData = useCallback(
    async (pageVal, sortColumn, sortBy) => {
      dispatch(startLoading());
      const response = await fetchAllServiceProvider(
        pageVal || 0,
        rowsPerPage,
        searchValuesRef.current,
        filterValuesRef.current,
        sortColumn || "Name",
        sortBy || "asc"
      );
      if (response.success && response.data?.serviceProviders) {
        setTotalRecords(response?.data?.totalRecords || 0);
        setServiceProviders(response?.data?.serviceProviders);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    },
    [rowsPerPage, dispatch]
  );

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    const fetchServiceProviders = async () => {
      setPage(0);
      dispatch(startLoading());
      const response = await fetchAllServiceProvider(
        0,
        rowsPerPageRef.current,
        searchValue,
        filterValuesRef.current,
        sortColumn,
        sortBy
      );
      if (response.success && response.data?.serviceProviders) {
        setTotalRecords(response?.data?.totalRecords || 0);
        setServiceProviders(response?.data?.serviceProviders);
        dispatch(stopLoading());
      } else {
        dispatch(stopLoading());
        enqueueSnackbar(response.data, { variant: "error" });
      }
    };
    const debouncer = setTimeout(() => {
      fetchServiceProviders();
    }, 500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [searchValue, dispatch, sortBy, sortColumn]);

  useEffect(() => {
    fetchData();
  }, [fetchData, filterValues]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    rowsPerPageRef.current = parseInt(event.target.value, 10);
    setPage(0);
  };

  const fetchServiceCategories = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllServiceCategory();
    if (response.success && response.data) {
      setServiceCategories(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  useEffect(() => {
    fetchServiceCategories();
  }, [fetchServiceCategories]);

  const fetchPreferredLanguage = useCallback(async () => {
    dispatch(startLoading());
    const response = await fetchAllPreferredLanguage();
    if (response.success && response.data) {
      setPreferredLanguage(response?.data);
      dispatch(stopLoading());
    } else {
      dispatch(stopLoading());
      enqueueSnackbar(response.data, { variant: "error" });
    }
  }, [dispatch]);

  useEffect(() => {
    fetchPreferredLanguage();
  }, [fetchPreferredLanguage]);

  const onView = (serviceProvider) => {
    navigate(
      `/admin/service-provider/view/${serviceProvider?.serviceProvider?.id}`
    );
  };

  const onEdit = (serviceProvider) => {
    navigate(
      `/admin/service-provider/edit/${serviceProvider?.serviceProvider?.id}`
    );
  };

  const handleClick = (serviceProvider, event) => {
    const url = `/admin/service-provider/flags/${serviceProvider?.serviceProvider?.id}`;
    window.open(url, "_blank");
  };

  const onDelete = (serviceProvider) => {
    setDeleteServiceProviderId(serviceProvider?.serviceProvider?.id);
    if (serviceProvider?.serviceProvider?.deleted) {
      setConfirmationMessage(REINSTATE_MESSAGE);
      dispatch(openConfirmationModal());
    } else {
      dispatch(openDeleteReasonModal());
    }
  };

  const deleteHandler = async (values) => {
    dispatch(startLoading());
    try {
      const response = await deleteServiceProvider(
        deleteServiceProviderId,
        values?.reason
      );
      fetchData(page, sortColumn, sortBy);

      let message = "Operation failed.";
      let variant = "error";

      if (response.success && response.data) {
        message = response.data.message || "Operation successful.";
        variant = response.data?.data?.isDuplicatePhoneNumber
          ? "error"
          : "success";
      } else if (typeof response.data === "string") {
        message = response.data;
      }

      enqueueSnackbar(message, { variant });
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar("An unexpected error occurred.", { variant: "error" });
    } finally {
      dispatch(stopLoading());
    }
  };

  const flaggedHandler = (e) => {
    setFilterValues((prev) => ({ ...prev, flagged: e.target.checked }));
  };

  const clearHandler = () => {
    setFilterValues(() => ({
      category: [ALL],
      status: [ALL],
      preferredLanguage: [ALL],
      resident: [ALL],
      flagged: false,
    }));
    setSearchValue("");
  };

  const downloadHandler = async () => {
    const response = await serviceProviderCsvReport(
      searchValue,
      filterValues.category,
      filterValues.preferredLanguage,
      filterValues.status,
      filterValues.resident,
      sortColumn,
      sortBy,
      filterValues.flagged
    );
    if (response.data) {
      const fileName = "Service-provider-report.csv";
      const file = new Blob([response.data], { type: "application/csv" });
      const url = window.URL.createObjectURL(file);
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = url;
      anchor.click();
    } else {
      enqueueSnackbar(response.data, { variant: "error" });
    }
  };

  return (
    <>
      <Grid container spacing={2} paddingBottom={2}>
        <Grid item md={6}>
          <OutlinedInput
            fullWidth
            placeholder="Search by name, phone no., email, business name"
            size={SMALL}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            startAdornment={
              <InputAdornment position={START}>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item md={6}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            options={[
              ALL,
              ...filterValues?.preferredLanguage.filter(
                (selectedValue) => selectedValue !== ALL
              ),
              ...preferredLanguage
                .map((language) => language.id)
                .filter(
                  (option) =>
                    !filterValues?.preferredLanguage.includes(option) &&
                    option !== ALL
                ),
            ]}
            getOptionLabel={(option) => {
              const language = [
                { id: ALL, name: ALL },
                ...preferredLanguage,
              ].find((s) => s.id === option);
              return language?.name || "";
            }}
            value={filterValues?.preferredLanguage}
            onChange={(_, newValue) => {
              let event = {
                target: {
                  value: newValue,
                },
              };
              handleInputChange(
                event,
                "preferredLanguage",
                setFilterValues,
                filterValues,
                () => { },
                ALL
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Preferred Language"
                name="preferredLanguage"
              />
            )}
          />
        </Grid>

        <Grid item md={6}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            options={[
              ALL,
              ...filterValues?.category.filter(
                (selectedValue) => selectedValue !== ALL
              ),
              ...serviceCategories
                .map((serviceCategory) => serviceCategory.id)
                .filter(
                  (option) =>
                    !filterValues?.category.includes(option) && option !== ALL
                ),
            ]}
            getOptionLabel={(option) => {
              const category = [
                { id: ALL, name: ALL },
                ...serviceCategories,
              ].find((s) => s.id === option);
              return category?.name || "";
            }}
            value={filterValues?.category}
            onChange={(_, newValue) => {
              let event = {
                target: {
                  value: newValue,
                },
              };
              handleInputChange(
                event,
                "category",
                setFilterValues,
                filterValues,
                () => { },
                ALL
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Service Category" name="category" />
            )}
            renderTags={(selected, getTagProps) => {
              const displayedTags = selected.slice(0, 3);
              const hiddenTagsCount = selected.length - displayedTags.length;
              return (
                <Box display="flex" alignItems="center">
                  {displayedTags.map((value, index) => (
                    <Chip
                      size="small"
                      key={value}
                      label={
                        value === ALL
                          ? ALL
                          : serviceCategories.find(
                            (serviceCategory) => serviceCategory.id === value
                          )?.name
                      }
                      {...getTagProps({ index })}
                    />
                  ))}
                  {hiddenTagsCount > 0 && <Box ml={1}>+{hiddenTagsCount}</Box>}
                </Box>
              );
            }}
          />
        </Grid>
        <Grid item md={6}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            options={[
              ALL,
              ...filterValues?.resident.filter(
                (selectedValue) => selectedValue !== ALL
              ),
              ...RESIDENT_PROVIDER_FILTER.filter(
                (option) =>
                  !filterValues?.resident.includes(option) && option !== ALL
              ),
            ]}
            value={filterValues?.resident}
            onChange={(_, newValue) => {
              let event = {
                target: {
                  value: newValue,
                },
              };
              handleInputChange(
                event,
                "resident",
                setFilterValues,
                filterValues,
                () => { },
                ALL
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Resident Provider"
                name="resident"
              />
            )}
            renderTags={(selected, getTagProps) => {
              const displayedTags = selected.slice(0, 2);
              const hiddenTagsCount = selected.length - displayedTags.length;
              return (
                <Box display="flex" alignItems="center">
                  {displayedTags.map((value, index) => (
                    <Chip
                      size="small"
                      key={value}
                      label={
                        value === ALL
                          ? ALL
                          : RESIDENT_PROVIDER_FILTER.find(
                            (resident) => resident === value
                          )
                      }
                      {...getTagProps({ index })}
                    />
                  ))}
                  {hiddenTagsCount > 0 && <Box ml={1}>+{hiddenTagsCount}</Box>}
                </Box>
              );
            }}
          />
        </Grid>
        <Grid item md={6}>
          <Autocomplete
            size="small"
            disableCloseOnSelect
            multiple
            options={[
              ALL,
              ...filterValues?.status.filter(
                (selectedValue) => selectedValue !== ALL
              ),
              ...SERVICE_PROVIDER_STATUS_FILTER.filter(
                (option) =>
                  !filterValues?.status.includes(option) && option !== ALL
              ),
            ]}
            value={filterValues?.status}
            onChange={(_, newValue) => {
              let event = {
                target: {
                  value: newValue,
                },
              };
              handleInputChange(
                event,
                "status",
                setFilterValues,
                filterValues,
                () => { },
                ALL
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Status" name="status" />
            )}
            renderTags={(selected, getTagProps) => {
              const displayedTags = selected.slice(0, 2);
              const hiddenTagsCount = selected.length - displayedTags.length;
              return (
                <Box display="flex" alignItems="center">
                  {displayedTags.map((value, index) => (
                    <Chip
                      size="small"
                      key={value}
                      label={
                        value === ALL
                          ? ALL
                          : SERVICE_PROVIDER_STATUS_FILTER.find(
                            (status) => status === value
                          )
                      }
                      {...getTagProps({ index })}
                    />
                  ))}
                  {hiddenTagsCount > 0 && <Box ml={1}>+{hiddenTagsCount}</Box>}
                </Box>
              );
            }}
          />
        </Grid>
        <Grid
          item
          md={6}
          display={FLEX}
          alignItems={CENTER}
          justifyContent={SPACE_BETWEEN}
        >
          <Grid>
            <FormControl>
              <FormControlLabel
                key={"flagged"}
                control={
                  <Checkbox
                    sx={{
                      color: DEEP_OCEAN_BLUE,
                    }}
                    size={SMALL}
                    checked={filterValues.flagged}
                    onChange={flaggedHandler}
                  />
                }
                label={
                  <Typography className="label__fontsize">
                    Only Flagged
                  </Typography>
                }
              />
            </FormControl>
          </Grid>
          <Grid className="resident__clear__button">
            <Button
              variant="contained"
              className="submit__button"
              onClick={clearHandler}
            >
              Clear All
            </Button>
          </Grid>
          <Grid>
            <Button
              variant="contained"
              className="submit__button"
              onClick={downloadHandler}
            >
              Download CSV
            </Button>
          </Grid>
          <Grid>
            <Button
              variant="contained"
              className="submit__button"
              onClick={() => navigate("/admin/service-provider/create")}
            >
              + Add
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <DataTable
        from={SERVICE_PROVIDER_LIST}
        headers={[
          { label: "Name" },
          { label: "Phone Number" },
          { label: "Business Name", with: "35%" },
          filterValues.flagged ? { label: "Flag Count" } : null,
          { label: "Category" },
          { label: "Title" },
          { label: "Email" },
          { label: "Status" },
          { label: "Preferred Language" },
          { label: "Actions" },
        ]}
        data={serviceProviders?.map((row) => {
          const formattedPhoneNo =
            row?.serviceProvider?.phone &&
              row.serviceProvider.phone.length === 10
              ? `${row.serviceProvider.countryCode
              }-${row.serviceProvider.phone.slice(
                0,
                5
              )}-${row.serviceProvider.phone.slice(5)}`
              : "-";

          return {
            id: row?.id,
            commonColumns: [
              row?.serviceProvider?.firstName
                ? row?.serviceProvider?.firstName +
                " " +
                row?.serviceProvider?.lastName
                : "-",
              formattedPhoneNo.trim() || "-",
              row?.serviceProvider?.businessName || "-",
              filterValues.flagged ? row?.serviceProviderFlags.length : null,
              row?.serviceProvider?.categories
                ?.map((category) => category.name)
                .join(", ") || "-",
              row?.serviceProvider?.title || "-",
              row?.serviceProvider?.email || "-",
              row?.serviceProvider?.status || "-",
              row?.serviceProvider?.preferredLanguage?.name || "-"
            ],
            data: row,
          };
        })}
        onView={onView}
        onEdit={onEdit}
        onDelete={onDelete}
        sortOrderHandler={sortOrderHandler}
        totalRecords={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleClick={handleClick}
        sortBy={sortBy}
        sortColumn={sortColumn}
      />
      <ConfirmationModal
        message={confirmationMessage}
        onOkHandler={deleteHandler}
        onCancelHandler={() => { }}
        cancelText="No"
        okText="Yes"
      />
      <DeleteReasonModal
        onOkHandler={deleteHandler}
        onCancelHandler={() => { }}
        cancelText="Cancel"
        okText="Submit"
        from={SERVICE_PROVIDER_LIST}
      />
      <FlagModal
        openFlagPopover={openFlagPopover}
        setOpenFlagPopover={setOpenFlagPopover}
        flagMessage={flagMessage}
        setFlagMessage={setFlagMessage}
        from={SERVICE_PROVIDER_LIST}
      />
      <Loader />
    </>
  );
};

export default List;
