import adminRootInstance from "../utils/config/axios-config";

export const UploadPreboardedPhoneNumbers = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await adminRootInstance.post(
      `/upload/preboarded-phone-numbers`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response?.data?.success) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      return {
        success: false,
        data: response?.data?.message || "Something went wrong!",
      };
    }
  } catch (err) {
    return {
      success: false,
      data: "Something went wrong!",
    };
  }
};
