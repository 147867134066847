import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import "./Auth.scss";
import { emailValidator, passwordValidator } from "../../helpers/validation";
import { handleAdminLogin } from "../../services/login";
import { useDispatch } from "react-redux";
import { loginHandler, loginHandlerWithId } from "../../redux/Slices/AuthSlice";
import { startLoading, stopLoading } from "../../redux/Slices/CommonSlice";
import { jwtDecode } from "jwt-decode";

const Login = () => {
  const navigate = useNavigate();
  const validationSchema = Yup.object({
    email: emailValidator,
    password: passwordValidator,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      dispatch(startLoading());
      const response = await handleAdminLogin(values);
      if (response?.success) {
        const previousURL = localStorage.getItem("previousURL");
        const tokenInfo = jwtDecode(response?.data?.token);
        localStorage.setItem("accessToken", response?.data?.token);
        localStorage.setItem("refreshToken", response?.data?.refreshToken);
        localStorage.setItem("userName", tokenInfo?.name);
        localStorage.setItem("id", tokenInfo?.id);
        dispatch(loginHandler(tokenInfo?.name));
        dispatch(loginHandlerWithId(tokenInfo?.id));
        dispatch(stopLoading());
        if (previousURL) {
          navigate(previousURL);
          localStorage.removeItem("previousURL");
        } else {
          navigate("/admin/home");
        }
      } else {
        dispatch(stopLoading());
        setError(response?.data);
      }
    },
  });

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <div className="Login">
      <div className="Login__header">
        <img
          src={require("../../assets/images/nivasi-icon-dark.png")}
          alt="Main Logo"
          height="50px"
        />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="Login__form">
          <div className="Login__input__Wrapper">
            <label>Email</label>
            <input
              className="Login__input__field"
              name="email"
              placeholder="Enter email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              onClick={() => setError("")}
            />
            {formik.touched.email &&
              formik.errors.email &&
              formik.submitCount > 0 && (
                <span className="Login__input__error">
                  {formik.errors.email}
                </span>
              )}
          </div>
          <div className="Login__input__Wrapper">
            <label>Password</label>
            <div className="Login__eyeicon__wrapper">
              <input
                className="Login__input__field"
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                onClick={() => setError("")}
              />
              <button
                className="Login__eyeicon__button"
                type="button"
                onClick={(e) => {
                  handleShowPassword(e);
                }}
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </button>
            </div>
            {formik.touched.password &&
              formik.errors.password &&
              formik.submitCount > 0 && (
                <span className="Login__input__error">
                  {formik.errors.password}
                </span>
              )}
          </div>
          <div>
            <button className="Login__submit__button" type="submit">
              Login
            </button>
          </div>
          {error && <span className="Login__error__span">{error}</span>}
        </div>
      </form>
    </div>
  );
};
export default Login;
